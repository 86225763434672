import {Map} from 'immutable';
import lodash from 'lodash';

export const getElementalFilterDataFromRaqbData = item => {
    const meta = {
        usedFields: []
    };
    if (!item)
        return undefined;
    return getElementalFilterDataFilterFromRaqbRuleOrGroup(item, meta);
};

const getElementalFilterDataFilterFromRaqbRuleOrGroup = (item, meta) => {
    if (item) {
        const type = item.get('type');
        const children = item.get('children1');
        if ((type === 'group' || type === 'rule_group') && children && children.size) {
            return getElementalFilterDataGroupFromRaqbGroup(item, meta);
        } else if (type === 'rule') {
            return getElementalFilterDataFilterFromRaqbItem(item, meta);
        }
        return undefined;
    }
};

const getElementalFilterDataGroupFromRaqbGroup = (item, meta) => {
    const properties = item.get('properties') || new Map();
    const children = item.get('children1');
    const list = children
        .map((currentChild) => getElementalFilterDataFilterFromRaqbRuleOrGroup(currentChild, meta))
        .filter((currentChild) => typeof currentChild !== 'undefined');
    if (!list.size)
        return undefined;

    let conjunction = properties.get('conjunction');
    if (!conjunction || list.size === 1)
        conjunction = 'AND';
    const filters = list.toArray();
    const filterType = item.get('type');
    const parseType = (filterType === 'group') ? 'filter_group' : 'filter';
    const resultQuery = {
        filter_type: parseType,
        filters,
        operator: conjunction.toUpperCase()
    };
    return filterType === 'group' ? resultQuery : list.toArray()[0];
};

const getElementalFilterDataFilterFromRaqbItem = (item, meta) => {
    console.log('Create filter data from item', item.toJS());

    let filter_params = {};
    let filterDef = item.toJS();
    let raqbProperties = filterDef.properties;
    // if something is the value .. trust the rest of the fields are available
    if (raqbProperties.value && raqbProperties.value?.length > 0 ){

        // value coming back from the raqb widget - in cases of custom widgets its often the complete object
        let raqbValue = lodash.cloneDeep(raqbProperties.value[0]);

        console.log('raqbValue', raqbValue);

        // TODO "cleanup" the value
        //    remove the cart_filter as appropriate
        //    cast / massage as appropriate based on valueType

        // remove null date filter
        if ( raqbValue?.date_filter === null ){
            delete raqbValue.date_filter;
        }

        //  just remove date_filter if the search is not date constrained
        if ( raqbValue?.date_filter?.search_type === 'over_all_time'){
            delete raqbValue.date_filter;
        }

        console.log('cleaned raqbValue', raqbValue);

        // as defined in in raqb config.types
        let raqbValueType = raqbProperties.valueType[0];

        let raqbOperator = raqbProperties.operator;

        // keys in the form key1.key2.key3 from the raqb config.fields  eg. profile_metric.email_metric.opened
        let raqbField = raqbProperties.field;

        if(raqbField.startsWith('profile_metric.email_metric') ||
            raqbField.startsWith('profile_metric.shop_metric')){
            // figure out model from our field def. operator comes from the raqb value, everything else comes from
            // the value given back from the widget which is built to be compatible with the elemental filter_params. For example
            // the type number_over_timeframe gives back  { value: 1, date_filter: ... }
            let [ cat1, cat2, metricModel ] = raqbField.split('.');

            if ( raqbValue ) {
                filter_params = {
                    ...raqbValue,
                    type: 'metric',
                    model: metricModel,
                    condition: raqbOperator
                };
            }

        }
        else if (raqbField.startsWith('profile_metric.profile_prop_change')) {
            let [ cat1, cat2, propName, propType ] = raqbField.split('.');
            if ( raqbValue ) {
                filter_params = {
                    ...raqbValue,
                    type: 'event',
                    prop_name: propName
                };
            }
        }
        else if (raqbField.startsWith('profile')) {
            // includes profile_prop_values and profile_attribute_values ( eg: profile.email )
            let [ catProfile, subType, subTypeName ] = raqbField.split('.');

            if ( raqbValue ) {
                // TODO: need to add support for the the date_filter .. the raqbValue will need to be spread in that case ..
                //  see the examples above for metric or profile_prop_change

                if ('profile_attribute_value' == subType ){
                    filter_params = {
                        value: raqbValue, // the value in this case is just a scalar so don't spread it
                        type: subType,
                        attribute: subTypeName,
                        condition: raqbOperator
                    };
                }
                else {
                    filter_params = {
                        value: raqbValue, // the value in this case is just a scalar so don't spread it
                        type: subType,
                        prop_name: subTypeName,
                        condition: raqbOperator
                    };
                }
            }
        }

        console.log('filter_params', filter_params);

        return {
            filter_type: 'filter',
            filter_params: filter_params
        };

    }
};
