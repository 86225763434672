import React, {useEffect, useMemo, useState} from 'react';
import {Autocomplete, Box, Button, Checkbox, FormControlLabel, List, ListItem, TextField} from '@mui/material';
// import TableComponent from './TableComponent'; // TODO: can be deleted as soon as the new method is confirmed
import {Link} from 'react-router-dom';
import dynamicAudience from 'assets/icons/audiences/dynamic_aud.svg';
import staticAudience from 'assets/icons/audiences/static_aud.svg';
import {withStyles} from '@mui/styles';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import * as http from 'util/elemental_axios_client';
import * as elemental_axios_client from 'util/elemental_axios_client';
import {apiV1OrgUri} from 'constants/apiRoutes';
import {Loader} from 'components/index';
import {useImmer} from 'use-immer';
import MutationLoadingButton from 'components/MutationLoadingButton';

const dynamicIcon = <img src={dynamicAudience} alt='uploadIcon'/>;
const staticIcon = <img src={staticAudience} alt='uploadIcon'/>;

// TODO: could be generalized into "multi value selector" .. not just for audiences
export const AudienceSelector = ({ value, onChange, audiences }) => {

    const [ selectedAudiencesToInclude, setSelectedAudiencesToInclude ] = useState([]);
    const [ selectedAudiences, setSelectedAudiences ] = useImmer(value);

    const onClickIncludeAudiences = () => {
        setSelectedAudiences( draft => { draft = draft.push(...selectedAudiencesToInclude); });
        setSelectedAudiencesToInclude([]);
    };

    const onClickRemoveAudience = ( audienceToRemove ) => {
        setSelectedAudiences( draft => {
            const index = draft.findIndex(audience => audience.id === audienceToRemove.id);
            if (index !== -1) {
                draft.splice(index, 1); // Directly mutate the draft by removing the item
            }
        });
    };

    useEffect( () => {
        if ( onChange ){
            onChange(selectedAudiences);
        }
    }, [selectedAudiences]);

    const selectedAudienceIds = selectedAudiences.map(audience => audience.id);
    const availableAudiences = audiences.filter(audience => !selectedAudienceIds.includes(audience.id));
    const selectedAudiencesSorted = [...selectedAudiences].sort((a, b) => a.name.localeCompare(b.name));

    return (
        <>
                <Box display='inline-flex' alignItems='center' justifyContent='space-between' gap={2} sx={{ width: '100%' }}>

                    { availableAudiences?.length > 0 &&
                        <Autocomplete
                            multiple
                            disableCloseOnSelect
                            id='multiple-limit-tags'
                            options={availableAudiences}
                            getOptionLabel={(audience) => audience.name }
                            value={selectedAudiencesToInclude}
                            renderInput={(params) => (
                                <TextField {...params} fullWidth={true} label='Choose audiences ...'  fullWidth
                                />
                            )}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                    {option.type === 'dynamic' ? dynamicIcon : staticIcon}
                                    {option.name}
                                </li>
                            )}
                            onChange={(e,value) => setSelectedAudiencesToInclude(value)}
                            size='small'
                            sx={{ width: '100%' }}

                        />
                    }
                </Box>
                { selectedAudiencesToInclude.length > 0 &&
                    <Box>
                    <Button
                        disabled={selectedAudiencesToInclude.length < 1}
                        className='new_primary_button'
                        onClick={onClickIncludeAudiences}
                    >Add {selectedAudiencesToInclude.length} audiences</Button>
                    </Box>
                }
                { selectedAudiencesSorted.length > 0 &&
                    <Box>
                    <List>

                        { selectedAudiencesSorted.map( (audience) => {
                            return ( <ListItem key={audience.id}>
                                {audience.type === 'dynamic' ? dynamicIcon : staticIcon}   { audience.name } <Button onClick={() => { onClickRemoveAudience(audience);}} >remove</Button> </ListItem> );
                        })
                        }
                    </List>
                    </Box>
                }
        </>

    );
};


const Audiences = ( { campaign, onSearch, onSave })  => {

    const [selectedAudiences, setSelectedAudiences] = useState([]);

    const queryClient = useQueryClient();

    const { data: allAudiences, isSuccess: allAudiencesSuccess } = useQuery({
        queryKey: ['audiences'],
        queryFn: async () => {
            return await http.get(`${apiV1OrgUri}/audiences?per_page=1000`);
        }
    });

    const mutationCampaignSetAudiences = useMutation({
        mutationFn: ( audienceIds ) => {
            return elemental_axios_client.post(`/campaigns/${campaign.id}/audiences`, audienceIds);
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries( { queryKey: ['campaigns', campaign.id] } );
            onSave();
        }
    });

    const saveAudienceSelector = async () => {
        const audienceIds = selectedAudiences.map(audience => audience.id );
        mutationCampaignSetAudiences.mutate({ segments: audienceIds });
    };

    if (!campaign || !allAudiencesSuccess) {
        return <Loader/>;
    }

    const selectorHasChanges = (JSON.stringify(selectedAudiences.map(a=>a.id).sort()) != JSON.stringify(campaign.segments.map(a=>a.id).sort()));

    return (
        <Box>
            <AudienceSelector audiences={allAudiences.audiences} onChange={setSelectedAudiences} value={campaign.segments} />
            <MutationLoadingButton
                disabled={!selectorHasChanges}
                mutation={mutationCampaignSetAudiences}
                onClick={saveAudienceSelector} color='primary' variant='contained'
                sx={{marginTop: 1, marginRight: 0, width: 'max-content'}}>
                Save Audiences
            </MutationLoadingButton>
        </Box>
    );
};
export default Audiences;
