import {Box, Typography} from '@mui/material';
import {RunningWithErrorsTwoTone, UpdateTwoTone} from '@mui/icons-material';
import {DateTime} from 'luxon';
import React from 'react';
import {formatDateTime} from 'util/helper';

const WaitActive = ( {activeObj: wait} ) => {
    return (
        <Box className='flow-sidebar__body-hihlighter' display='flex' style={{padding: '5px 25px', marginTop: 30}}>
            <Box sx={{width: '100%'}} display='flex' flexDirection='column'>
                <Box display='flex' flexGrow={1} alignItems='center' mb={2}>
                    <UpdateTwoTone style={{marginRight: 10}}/>
                    <Typography component='div' variant='subtitle1'>Active Since: <span style={{fontWeight: 400}}>{formatDateTime(wait.start)}</span></Typography>
                </Box>
                <Box display='flex' flexGrow={1} alignItems='center' mb={2}>
                    <RunningWithErrorsTwoTone style={{marginRight: 10}}/>
                    <Typography component='div' variant='subtitle1'>Current Delay: <span style={{fontWeight: 400}}>{wait.descriptive_label}</span></Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default WaitActive;