import React, { useState, useEffect } from 'react';
import { useParams, withRouter } from 'react-router';
import {Box, Typography, CircularProgress, Container} from '@mui/material';
import axios from 'axios';
import { useQuery, useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import KratosFlowUIForm from './components/KratosFlowUIForm';
import { kratosPublicUrl } from 'constants/apiRoutes';
import {useHistory} from 'react-router';
import {useAuth} from '../../contexts/AuthContext';
import el_background from '../../assets/icons/main/elemental_background.svg';

// TODO:
//  handle if already verified and coming to this from clicking a link
//  handle the different errors ( flow expired / page refresh )
//  prefill the code so it can work coming in from the email


function getCsrfTokenValue (nodes) {
    const csrfNode = nodes.find(node =>
        node.attributes?.name === 'csrf_token' && node.attributes?.type === 'hidden'
    );
    return csrfNode ? csrfNode.attributes.value : null;
}

const VerificationPage = ({}) => {

    const { currentKratosSession } = useAuth();

    const [formData, setFormData] = useState(null);
    const [codeFormData, setCodeFormData ] = useState(null);
    const { getValues } = useForm();
    const history = useHistory();

    const fetchVerificationFlow = useQuery(
        {
            queryKey: ['verification-flow'],
            queryFn: async () => {
                const searchParams = new URLSearchParams(window.location.search);
                const flow = searchParams.get('flow');
                const code = searchParams.get('code');

                const params = new URLSearchParams();
                if (flow) params.append('flow', flow);
                if (code) params.append('code', code);

                const url = `${kratosPublicUrl}/self-service/verification/browser?${params.toString()}`;
                const response = await axios.get(url, { 'method' : 'code' }, {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                    withCredentials: true
                });
                return response.data;
            },
            onError: ( error, variables, context ) => {
                // .catch((err: AxiosError) => {
                //             switch (err.response?.status) {
                //             case 410:
                //                 // Status code 410 means the request has expired - so let's load a fresh flow!
                //             case 403:
                //                 // Status code 403 implies some other issue (e.g. CSRF) - let's reload!
                //                 return router.push("/verification")
                //
                //             }
            },
            //
            enabled: true,
        }
        ,[]);

    const submitVerification = useMutation({
        mutationFn: (data) => {
            return axios.post(`${formData?.ui?.action}`, data, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                withCredentials: true
            }).then( response => response.data );
        }}
    );

    useEffect(() => {
        // if the state is 'choose_method' .. create the payload manually for our known email
        // https://www.ory.sh/docs/kratos/reference/api#tag/frontend/operation/updateVerificationFlow
        // and mutate it so we can get the next form iteration to actually show the user
        setFormData(fetchVerificationFlow.data);
    }, [fetchVerificationFlow.data] );

    useEffect( () => {
        if( fetchVerificationFlow.data?.state == 'choose_method' ) {
            let csrfValue = getCsrfTokenValue(fetchVerificationFlow.data.ui.nodes);
            let payload = {
                'email': currentKratosSession?.identity.verifiable_addresses[0].value,
                'method': 'code',
                'csrf_token': csrfValue
            };
            //console.log('payload', payload, 'action', formData.ui.action );
            submitVerification.mutate(payload, { onSuccess: (data) => setCodeFormData(data) } );
        }
    }, [formData] );


    if (fetchVerificationFlow.isLoading) {
        return (
            <Box display='flex' justifyContent='center' alignItems='center' height='100vh'>
                <CircularProgress />
            </Box>
        );
    }

    if (fetchVerificationFlow.isError) {
        return (
            <Box display='flex' justifyContent='center' alignItems='center' height='100vh'>
                <Typography variant='h6' color='error'>
                    An error occurred while fetching verification flow.
                </Typography>
            </Box>
        );
    }

    return (
        <div className='el_background'>
            <img src={el_background} alt='el_background' />
            {codeFormData &&
                <Container component='main' maxWidth='xs' style={{margin: '120px 100px'}}>
                <Box p={3}>
                    <Typography variant='h4' gutterBottom>
                        Verification
                    </Typography>
                    {codeFormData &&
                        <KratosFlowUIForm
                            initialFormUI={codeFormData.ui}
                            onError={(error) => {
                                console.error('Verification failed:', error);
                                // Handle error, possibly show error message
                            }}
                        />
                    }
                </Box>
                </Container>
            }
        </div>
    );
};

export default withRouter(VerificationPage);