import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Divider, Typography } from '@mui/material';
import { useJourney } from '../../../../api/Journeys/journey_queries';
import  useLocalStore  from '../../../../useLocalStore';

import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
import IssuesList from 'components/IssuesList';

import { ISSUE_SECTIONS } from 'pages/Journeys/JourneyConstants';

export const parseIssues = (issue) => {
    let isValidSection = true;
    let sub_section = issue.sub_sections.filter(item => item.issues.find(i => !i.is_valid));
    let section;

    while (isValidSection) {
        Object.entries(sub_section[0].issues).map((item) => {
            if(item[1].is_valid_section) {
                isValidSection = true;
                sub_section = item[1].sub_sections;
            } else {
                isValidSection = false;
                section = [
                    issue.model_id,
                    item[1].issue_codes_and_readable[0].code,
                    item[1].issue_codes_and_readable[0].readable.replace(' [actual readable coming soon]', ''),
                    ISSUE_SECTIONS[item[1].section]
                ];
            }
        });
    }

    return section;
};

const JourneyIssuesSidebar = () => {
    const { id } = useParams();

    const { status, data: journey, error, isFetching } = useJourney(id);

    const [ triggerIssue, setTriggerIssue ] = useState(false);
    const [ dropoutFilter, setDropoutFilter ] = useState(false);
    const [ stepIssues, setStepIssues ] = useState(false);

    const [setSidebar, resetSidebar] = useLocalStore(
        (state) => [state.setSidebar, state.resetSidebar]
    );

    // useEffect(() => {
    //     if(journey) {
    //         const { activation_issues } = journey;
    //         sectionIssue(activation_issues);
    //     }
    // }, [journey]);

    const sectionIssue = (issues) => {
        if(!issues.is_valid_section) {
            if(issues.issue_codes.includes('trigger_empty')) {
                setTriggerIssue(true);
            }
        }

        if(issues.sub_sections.find(issue => issue.section === 'dropout_filter')){
            setDropoutFilter(true);
        }

        const issuesParsed = issues.sub_sections.filter(item => item.issues.find(issue => !issue.is_valid));
        const stepIssues = issuesParsed.length > 0 && issuesParsed[0].issues.filter(item => !item.is_valid);

        const list = [];
        Object.entries(stepIssues).map(issue => list.push(parseIssues(issue[1])));
        setStepIssues(list);
    };

    const onTrigger = () => {
        const payload = {
            open: true,
            name: 'JourneyTrigger',
            params: {
                flowId: id
            }
        };

        setSidebar(payload);
    };

    const onDropout = () => {
        const payload = {
            open: true,
            name: 'JourneyDropout',
            params: {
                flowId: id
            }
        };

        setSidebar(payload);
    };


    return (
        <Box className='flow-sidebar'>
            <Box className='flow-sidebar__header'>
                <Typography component='div' variant='subtitle1' className='flow-sidebar_title' style={{border: 0}}>
                    <ErrorTwoToneIcon />
                    Activation Issues
                </Typography>
            </Box>



            <Box className='flow-sidebar__body' sx={{px: 2}}>
                <IssuesList label={'Journey'} issues={journey?.activation_issues} />
            </Box>
        </Box>
    );
};

export default JourneyIssuesSidebar;
