/* eslint-disable react/display-name */
import React from 'react';
import { Box, Typography } from '@mui/material';
import { withRouter } from 'react-router';
import { useParams } from 'react-router-dom';
import { Handle, Position } from 'reactflow';
import EditNodeHeader from './Common/EditNodeHeader';
import EditNodeBody from './Common/EditNodeBody';
import { useJourneyStepStats, useJourneyStep } from '../../../../../api/Journeys/journey_queries';
import useLocalStore from '../../../../../useLocalStore';

const HANDLE_STYLE = {
    height: 13,
    width: 13,
    position: 'absolute',
    background: 'transparent', //'#E6F5FD',
    boxSizing: 'border-box',
    border: '2px solid transparent',
    bottom: '0px',
    left: '50%'
};




const MessageEmailEditNode = React.memo(({ data, isConnectable, history }) => {
    const { id } = useParams();
    const { isDisabled, stepId, type, status } = data;

    const { data: step } = useJourneyStep(id, stepId);
    const [setSidebar] = useLocalStore((state) => [state.setSidebar]);

    const handleSidebar = (event) => {
        const payload = {
            open: true,
            name: 'JourneyStepEdit',
            params: {
                stepId: data.stepId
            }
        };
        setSidebar(payload);
    };

    return (
        step ?
            <div className={!isDisabled ? 'flow-message-node' : 'flow-message-node disabled'}>
                <Handle
                    type='target'
                    position={Position.Top}
                    style={{ height: 0, border: 0, background: 'transparent' }}
                    isConnectable={isConnectable}
                />

                <EditNodeHeader step={step} handleSidebar={handleSidebar} />
                {step?.can_activate &&
                    <EditNodeBody status={step} handleSidebar={handleSidebar}>
                        <div className='flow-node__body' onClick={handleSidebar}>
                            <Typography variant='body2' style={{fontSize: 11}}>
                                {step?.message?.preview_text?.substr(0,100)}
                            </Typography>
                        </div>
                    </EditNodeBody>
                }

                <Handle
                    type='source'
                    position={Position.Bottom}
                    style={HANDLE_STYLE}
                    isConnectable={isConnectable}
                />
            </div>
            :
            <></>
    );
});

export default withRouter(MessageEmailEditNode);
