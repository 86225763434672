import React, { useEffect } from 'react';
import { Select, MenuItem, FormControl } from '@mui/material';

const CustomBooleanComponent = (props) => {

    const { setValue, value } = props;

    useEffect(() => {
        setValue(value);
    }, []);

    const handleChange = (e) => {
        setValue(e.target.value);
    };

    return (
        <FormControl>
            <Select MenuProps={{ style: {zIndex: 35001} }} onChange={handleChange} value={value} name='newProp'>
                <MenuItem value={true}>true</MenuItem>
                <MenuItem value={false}>false</MenuItem>
            </Select>
        </FormControl>
    );
};
export default CustomBooleanComponent;
