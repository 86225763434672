import React from 'react';
import { Box, Collapse, Typography } from '@mui/material';

const SidebarGroup = ({ open, icon, title, CTA, children }) => {
    return (
        <>
            <Box className='flow-sidebar__body-hihlighter' display='flex' style={{padding: '5px 0px'}} mb={1}>
                <Box sx={{width: '100%'}} display='flex'>
                    <Box display='flex' flexGrow={1} alignItems='center'>
                        {icon}
                        <Typography component='div' variant='subtitle1' style={{textTransform: 'capitalize'}}>{title}</Typography>
                    </Box>
                    {CTA}
                </Box>
                <Box sx={{width: '100%', display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', px: 2, py: 1}}>
                    <Collapse in={open} timeout='auto' style={{width: '100%'}} unmountOnExit>
                        {children}
                    </Collapse>
                </Box>
            </Box>
        </>
    );
};

export default SidebarGroup;
