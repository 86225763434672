import React from 'react';
import {Divider, List, ListItem, Paper, Typography} from '@mui/material';


const IssuesList = ({ issues, label = 'Issues', showAll = false }) => {
    const renderIssues = (issuesObject, showAll) => {
        const issueItems = [];

        const filterIssues = (issues) => {
            return showAll ? issues : issues.filter(issue => issue.status === 'error');
        };

        const processIssues = (issues, sectionName = '') => {
            const filteredIssues = filterIssues(Object.values(issues.issues_by_code));
            if (filteredIssues.length > 0) {
                issueItems.push(
                    <React.Fragment key={sectionName}>
                        <Typography variant='h6'>{sectionName}</Typography>
                        {filteredIssues.map((issue, index) => (
                            <div key={index}>
                                <ListItem>
                                    <div>
                                        <Typography variant='h6'>{issue.code} {issue.detail}</Typography>
                                        <Typography variant='subtitle1' color='textSecondary'>{issue.readable}</Typography>
                                        <Typography variant='body2' color={issue.status === 'error' ? 'error' : 'textSecondary'}>
                                            {issue.error} {`(${issue.status})`}
                                        </Typography>
                                    </div>
                                </ListItem>
                                {index !== filteredIssues.length - 1 && <Divider />}
                            </div>
                        ))}
                    </React.Fragment>
                );
            }

            if (issues.sections) {
                Object.entries(issues.sections).forEach(([subSectionName, subSectionIssues]) => {
                    processIssues(subSectionIssues, subSectionName);
                });
            }
        };

        processIssues(issuesObject);

        return issueItems;
    };

    if( !issues ){
        return <></>;
    }

    if (!issues.is_valid || showAll) {
        return <Paper elevation={3} style={{padding: '20px', maxWidth: '600px', margin: '20px auto'}}>
            <Typography variant='h5' gutterBottom>
                {label}
            </Typography>
            <List>
                {renderIssues(issues, showAll)}
            </List>
        </Paper>;
    }
};

export default IssuesList;

