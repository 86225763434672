// Display the draft version
import {Box} from '@mui/material';
import FilterReadable from 'components/FilterReadable';
import React from 'react';

const BranchFilterDraft = ({ draftObj }) => {

    return <div className='branch--filter' >
        <Box display='flex' flexDirection='column' pt={3} px={2}>
            <Box><FilterReadable filterData={draftObj.filter_data}/></Box>
        </Box>
    </div>;

};

export default BranchFilterDraft;