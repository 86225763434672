import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { Select, MenuItem, FormControl, TextField, Button, Tooltip } from '@mui/material';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { dateMaskFormat } from '../../../util/constants';
import { MuiConfig, MuiWidgets } from '@react-awesome-query-builder/mui';

import {useImmer} from 'use-immer';
import CartFilter from 'components/QueryBuilder/CustomComponents/CartFilter';

export const timeUnits = ['days', 'weeks'];
export const timeFrames = [
    {key: 'never', label: 'never'},
    {key: 'over_all_time', label: 'over all time'},
    {key: 'in_the_last', label: 'in the last'},
    {key: 'between', label: 'between'},
    {key: 'before', label: 'before'},
    {key: 'after', label: 'after'},
    {key: 'between_dates', label: 'between dates'}
];
export const flowTimeFrames = [{key: 'since_starting_this_flow', label: 'since journey started'} ] + timeFrames;

const CalendarFromDate = ( {value, handleChange}) => {
    return (
        <LocalizationProvider dateAdapter={AdapterLuxon}>
            <DesktopDatePicker
                mask={dateMaskFormat}
                value={value || null}
                onChange={handleChange}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        inputProps={{ ...params.inputProps, placeholder: 'Date' }}
                    />
                )}
            />
        </LocalizationProvider>
    );
};

const CalendarToDate = ( {value, handleChange, minValue} ) => {
    return (
        <LocalizationProvider dateAdapter={AdapterLuxon}>
            <DesktopDatePicker
                mask={dateMaskFormat}
                value={value || null}
                onChange={handleChange}
                minDate={DateTime.fromISO(minValue) || DateTime.fromISO('2021-01-01')}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        inputProps={{ ...params.inputProps, placeholder: 'Date' }}
                    />
                )}
            />
        </LocalizationProvider>
    );
};

const DateRange = ( { value, setValue, isJourney} ) => {

    // DateTime.fromISO(minValue) || DateTime.fromISO('2021-01-01')}
    const [dateRange, setDateRange] = useImmer( value ? value : {
        search_type : 'over_all_time',
        from_units: 10,
        to_units: 100,
        from_date: null,
        to_date: null,
        unit: 'days'
    });

    useEffect(() => {
        // TODO: should only call this when the value is valid
        setValue(dateRange);
    }, [dateRange]);


    // this should be passed down via the components
    const valueTimeFrameOptions = isJourney === 'journey' ? flowTimeFrames : timeFrames;

    // figure out styles on both of these
    //         <div className={`${(props.value?.search_type === 'over_all_time' || props.value?.search_type === 'since_starting_this_flow') && 'time_frame--inline'} time_frame_value`}>
    //             <div className={`value--when ${type === 'event' ? 'event' : ''}`}>
    return (
        <div>
            <FormControl className='when--form'>
                <Select
                    MenuProps={{style: {zIndex: 35001}}}
                    onChange={(e) => setDateRange( draft => { draft.search_type = e.target.value; })}
                    value={dateRange.search_type}
                    name='valueTimeFrame'>
                    {valueTimeFrameOptions.map((timeFrame, i) =>
                        <MenuItem key={i} value={timeFrame.key}>{timeFrame.label}</MenuItem>
                    )}
                </Select>
            </FormControl>
            {{
                'in_the_last': <>
                                <TextField type='number' placeholder='0' className='value--numeric'
                                            InputProps={{inputProps: {min: 1}}}
                                            onChange={(e) => setDateRange( draft => { draft.from_units = e.target.value; })}
                                            value={dateRange.from_units}
                                            name='in_the_last'/>
                                <FormControl style={{width: '80px'}}>
                                    <Select
                                        MenuProps={{style: {zIndex: 35001}}}
                                        onChange={(e) => setDateRange( draft => { draft.unit = e.target.value; })}
                                        className='value--unit'
                                        value={dateRange.unit || '' }
                                        name='valueTimeUnit'>
                                        <MenuItem value='' style={{display: 'none'}}></MenuItem>
                                        {timeUnits.map((timeUnit, i) =>
                                            <MenuItem key={i} value={timeUnit}>{timeUnit}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                </>,
                'before': <CalendarToDate value={dateRange.to_date} handleChange={(to_date) => setDateRange( draft=> { draft.to_date = to_date; })}/>,
                'after': <CalendarFromDate value={dateRange.from_date} handleChange={(from_date) => setDateRange( draft => {draft.from_date = from_date; })}/>,
                'between': <>
                    <NumericRange
                        value={{ from: dateRange.from_units, to: dateRange.to_units }}
                        setValue={(range) => setDateRange( draft => { draft.from_units = range.from; draft.to_units = range.to; })}
                    />
                    <FormControl style={{width: '80px'}}>
                        <Select
                            MenuProps={{style: {zIndex: 35001}}}
                            onChange={(e) => setDateRange( draft => { draft.unit = e.target.value; })}
                            className='value--unit'
                            value={dateRange.unit}
                            name='valueTimeUnit'>
                            <MenuItem value='' style={{display: 'none'}}></MenuItem>
                            {timeUnits.map((timeUnit, i) =>
                                <MenuItem key={i} value={timeUnit}>{timeUnit}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </>, // X any Y Date Units
                'between_dates': <>
                    <CalendarFromDate value={dateRange.from_date} handleChange={(from_date) => setDateRange( draft => {draft.from_date = from_date; })}/>
                    <CalendarToDate value={dateRange.to_date} handleChange={(to_date) => setDateRange( draft=> { draft.to_date = to_date; })}/>
                </>
            }[dateRange.search_type]}
        </div>
    );
};

// could potentially be swapped for the RAQB NumericRange component
const NumericRange = ( { value, setValue }) => {

    const getInitialValue = (value) => {
        let initialValue = {};
        if (value.from ){
            initialValue.from = value.from;
        }
        if (value.to ){
            initialValue.to = value.to;
        }
        return initialValue;
    };

    const [ numericRange, setNumericRange ] = useImmer(getInitialValue(value));

    useEffect(() => {
        // TODO: should only call this when the value is valid
        setValue(numericRange);
    }, [numericRange]);

    return (
        <>
            <TextField className='value--numeric'
                       type='number'
                       placeholder='0'
                       InputProps={{inputProps: {min: 1}}}
                       onChange={(e) => setNumericRange( draft => { draft.from = e.target.value; })}
                       value={numericRange.from || ''}
                       name='valueStart'/>
            <span>and</span>
            <TextField className='value--numeric'
                       type='number'
                       placeholder='0'
                       // TODO: alters the min/max of this input based on the start value the other input
                       // InputProps={{inputProps: {min: props.value?.[2] !== '' ? (parseInt(props.value[2]) + 1) : 1}}}
                       value={numericRange.to || ''}
                       onChange={(e) => setNumericRange( draft => { draft.to = e.target.value; })}
                       name='valueEnd'/>
        </>
    );
};



const ProfilePropChangeWidget = (props) => {

    const { value, setValue, field, prop_data_type } = props;

    const [profilePropChange, setProfilePropChange] = useImmer(value ? value : {
        prop_old_any: true,
        prop_old: '',
        prop_new_any: true,
        prop_new: ''
    });

    useEffect(() => {
        setValue(profilePropChange);
    }, [profilePropChange]);

    const numericFieldType = prop_data_type === 'integer' || prop_data_type === 'decimal';
    const stringFieldType = prop_data_type === 'string';
    const dateFieldType = prop_data_type === 'date';
    const booleanFieldType = prop_data_type === 'boolean';


    const fromAny = (
        <Select
            MenuProps={{ style: {zIndex: 35001} }}
            onChange={(e) => setProfilePropChange( draft => { draft.prop_old_any = e.target.value === 'true'; })}
            value={profilePropChange.prop_old_any ? 'true' : 'false'}
            name='oldProp'>
            <MenuItem value='true'>anything</MenuItem>
            <MenuItem value='false'>value</MenuItem>
        </Select>
    );

    const toAny = (
        <Select
            MenuProps={{ style: {zIndex: 35001} }}
            onChange={(e) => setProfilePropChange( draft => { draft.prop_new_any = e.target.value === 'true'; })}
            value={profilePropChange.prop_new_any ? 'true' : 'false'}
            name='oldProp'>
            <MenuItem value='true'>anything</MenuItem>
            <MenuItem value='false'>value</MenuItem>
        </Select>
    );

    const valueEventFields = (
        <div className='value--prop-value'>
            <span>from</span>
            {fromAny}
            {!profilePropChange.prop_old_any &&
                <TextField
                    type={`${numericFieldType ? 'number' : stringFieldType && 'text'}`}
                    placeholder='Old value'
                    InputProps={{inputProps: {step: prop_data_type === 'decimal' ? '0.1' : ''}}}
                    onChange={(e) => setProfilePropChange(draft => {
                        draft.prop_old = e.target.value;
                    })}
                    value={profilePropChange.prop_old || ''}
                    name='oldProp'
                />
            }
            <span>to</span>
            {toAny}
            {!profilePropChange.prop_new_any &&
                <TextField
                    type={`${numericFieldType ? 'number' : stringFieldType && 'text'}`}
                    placeholder='New value'
                    InputProps={{inputProps: {step: prop_data_type === 'decimal' ? '0.1' : ''}}}
                    onChange={(e) => setProfilePropChange(draft => {
                        draft.prop_new = e.target.value;
                    })}
                    value={profilePropChange.prop_new || ''}
                    name='newProp'
                />
            }
        </div>
    );

    const booleanEventFields = (
        <div className='value--prop-value'>
            {fromAny}
            {!profilePropChange.prop_old_any &&
                <FormControl>
                    <Select
                        MenuProps={{style: {zIndex: 35001}}}
                        onChange={(e) => setProfilePropChange(draft => {
                            draft.prop_old = e.target.value;
                        })}
                        value={profilePropChange.prop_old || ''}
                        name='oldProp'>
                        <MenuItem value='' style={{display: 'none'}}></MenuItem>
                        <MenuItem value='true'>true</MenuItem>
                        <MenuItem value='false'>false</MenuItem>
                    </Select>
                </FormControl>
            }
            <span>changed to</span>
            {toAny}
            {!profilePropChange.prop_new_any &&
                <FormControl>
                <Select
                MenuProps={{style: {zIndex: 35001}}}
                onChange={(e) => setProfilePropChange( draft => {draft.prop_new = e.target.value;})}
                value={profilePropChange.prop_new || ''}
                name='newProp'>
                <MenuItem value='' style={{display: 'none'}}></MenuItem>
                <MenuItem value='true'>true</MenuItem>
                <MenuItem value='false'>false</MenuItem>
                </Select>
                </FormControl>
            }
        </div>
    );

    // TODO: need to figure out the class name for enclosing container
    // <div className={`${(props.value?.[1] === 'over_all_time' || props.value?.[1] === 'since_starting_this_flow') && 'time_frame--inline'} time_frame_value`}>
    return (
        <div>
            {(numericFieldType || stringFieldType) && valueEventFields}
            {dateFieldType && <>
                {fromAny}
                {!profilePropChange.prop_new_any &&
                    <CalendarFromDate
                        value={profilePropChange.prop_old}
                        handleChange={(e) => setProfilePropChange( draft => { draft.prop_old = e.target.value; })}
                    />
                }
                {toAny}
                {!profilePropChange.prop_new_any &&
                    <CalendarToDate
                        value={profilePropChange.prop_new}
                        handleChange={(e) => setProfilePropChange(draft => {
                            draft.prop_new = e.target.value;
                        })}
                    />
                }
            </>
            }
            {booleanFieldType && booleanEventFields}
            <DateRange value={profilePropChange.date_filter} setValue={(value) => setProfilePropChange( draft => { draft.date_filter = value; })}/>
        </div>
    );
};


//  [ number of times ] over [ date range ]
const NumberOverTimeFrame = (props) => {

    const { setValue, value } = props;

    const [numberOverTimeFrame, setNumberOverTimeFrame] = useImmer( value ? value : {
        'value' : 1,
        'date_filter' : null
    });

    useEffect(() => {
        // TODO: should only call this when the value is valid
        console.log('metric filter changed', numberOverTimeFrame);
        setValue(numberOverTimeFrame);
    }, [numberOverTimeFrame]);

    // TODO: need to figure out the class name for enclosing container
    // <div className={`${(props.value?.[1] === 'over_all_time' || props.value?.[1] === 'since_starting_this_flow') && 'time_frame--inline'} time_frame_value`}>

    // TODO: show cartfilter for ecommerce based .. could be its own filter type
    return (
        <div>
            <MuiWidgets.MuiNumberWidget value={numberOverTimeFrame.value} setValue={(value) => setNumberOverTimeFrame( draft => { draft.value = value; })}/>
            <span>{ numberOverTimeFrame.value > 1 ? 'times' : 'time' }</span>
            <DateRange value={numberOverTimeFrame.date_filter} setValue={(value) => setNumberOverTimeFrame( draft => { draft.date_filter = value; })}/>
        </div>
    );
};




export { NumberOverTimeFrame, ProfilePropChangeWidget, DateRange, NumericRange };
