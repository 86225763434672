import {Box} from '@mui/material';
import ContentEmailThumbnailImg from 'components/ContentEmailThumbnailImg';
import React from 'react';


const EmailContentOverviewSimple = ({ contentContainer }) => {

    const overview = (
        <Box style={{ marginTop: 20, width: '100%', maxHeight: '35rem', height: 'max-content', overflow: 'auto', textAlign: 'center'}} >
            Subject: {contentContainer.subject}<br/>
            From: {contentContainer.from_name}{contentContainer.from_email}><br/>
            Id: {contentContainer.id}<br/>
            Screenshot-name: {contentContainer.screenshot_name}<br/>
            <ContentEmailThumbnailImg emailContent={contentContainer} />
        </Box>
    );
    return overview;
};

export default EmailContentOverviewSimple;