import { useQuery } from '@tanstack/react-query';
import * as http from 'util/elemental_axios_client';

export const useJourney = (id, options ) => {
    return useQuery(['journeys', id], async () => {
        return await http.get(`/flows/${id}`);
    }, options);
};

export const useJourneyStepStats = (id, stepId) => {
    const action = `/flows/${id}/steps/${stepId}/stats`;

    return useQuery(['journey_step_stats', stepId], async () => {
        return await http.get(action);
    });
};

export const useJourneyStep = (id, stepId) => {
    const action = `/flows/${id}/steps/${stepId}`;

    return useQuery(['journey_step', stepId], async () => {
        return await http.get(action);
    });
};


export const useAdjacencyVersion = (id, date) => {
    const action = `/flows/${id}/active/adjacency_list/versions/${date}}`;

    return useQuery(['journeys', id, 'adjacencyList', date ], async () => {
        return await http.get(action);
    });
};
