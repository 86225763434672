import React, {useContext, useEffect, useMemo, useState} from 'react';
import { Box, Typography } from '@mui/material';
import CustomBox from 'components/CustomBox';
import {formatDate, formatDateTime} from 'util/helper';
import Checkmark from 'assets/icons/main/check_mark_icon.svg';
import ActiveCheckmarkIcon from 'assets/icons/main/active_check_mark.svg';
import ActivePurchasedIcon from 'assets/icons/main/active_purchased_icon.svg';
import PurchasedIcon from 'assets/icons/main/purchase_btn_icon.svg';
import EmailIcon from 'assets/icons/main/email_icon.svg';
import Loader from 'components/Loader';

const Profile = (props) => {
    const { profileId } = props;
    // const userActivityData = customersActivity ? customersActivity.activities : [];
    // const userLogsData = customersLogs ? customersLogs.logs : [];
    const [ isLoading, setIsLoading ] = useState(true);

    const singleProfileData = {};
    const userActivityData = [];
    const userLogsData = [];
    const profileAudiences = {};


    useEffect(() => {
        const fetchData = async () => {
            // await getSingleProfileData(profileId);
            // await getAllAudiencesForProfile(profileId);
            // await getCustomersActivity(profileId);
            // await getCustomersLogs(profileId);
            setIsLoading(false);
        };
        fetchData();
        return () => cleanUp();
    }, []);

    const cleanUp = () => {
        // setCustomersLogs(null);
        // setCustomersActivity(null);
        // setSingleProfileData(null);
        // setProfileAudiences(null);
    };

    const renderStat = (statData) => (
        <Box className='left-box__top__stat'>
            <span>{singleProfileData ? statData : '-'}</span>
        </Box>
    );

    const renderCheckmark = (id) => (
        id ? <img src={ActiveCheckmarkIcon} alt='checkmark_true' /> : <img src={Checkmark} alt='checkmark_false' />
    );

    const activityColumns = useMemo(
        () => [
            { Header: 'Title', sticky: 'left', accessor: 'message_name', Cell ({row}) { return <Box display='flex' alignItems='center'><img src={EmailIcon} alt='True' style={{marginRight: '8px'}}/>{row.original.message_name ? row.original.message_name : '-'}</Box>; }, width: 200 },
            { Header: 'Open', accessor: 'opened', Cell ({row}) { return <div>{renderCheckmark(row.original.opened)}</div>; }, width: 100 },
            { Header: 'Click', accessor: 'clicked', Cell ({row}) { return <div>{renderCheckmark(row.original.clicked)}</div>; }, width: 100 },
            { Header: 'Outcome', accessor: 'converted', Cell ({row}) { return <div>{row.original.converted ? <img src={ActivePurchasedIcon} alt='True' /> : <img src={PurchasedIcon} alt='False' />}</div>; }, width: 100 },
            { Header: 'Date sent', accessor: 'sent_at', Cell ({row}) { return <div>{!row.original.sent_at ? '-' : formatDateTime(row.original.sent_at)}</div>; }, width: 100 },
            { Header: '', sticky: 'right', id: 'menu', Cell ({row}) { return true; }, width: 0}
        ],
        []
    );

    const logsColumns = useMemo(
        () => [
            { Header: 'Description', sticky: 'left', accessor: 'event_name', Cell ({row}) { return <div>{row.original.event_name}</div>; }, width: 200 },
            { Header: 'Date', accessor: 'date', Cell ({row}) { return <div>{!row.original.date ? '-' : formatDateTime(row.original.date)}</div>; }, width: 100 },
            { Header: '', sticky: 'right', id: 'menu', Cell ({row}) { return true; }, width: 0}
        ],
        []
    );


    const renderActivities = () => (
        <Box p={1}>
            {/*<TableComponent columns={activityColumns} style={{maxHeight: '400px'}} data={userActivityData} filterBy='message_name' />*/}
        </Box>
    );

    const renderLogs = () => (
        <Box p={1}>
            {/*<TableComponent columns={logsColumns} data={userLogsData} style={{maxHeight: '400px'}} filterBy='event_name' />*/}
        </Box>

    );

    const renderDetails = () => (
        singleProfileData ? (
            <Box className='right-box__details-box'>
                {singleProfileData.props?.map((prop, id) => (
                    <Box className='right-box__item' key={id}>
                        <Box className='item--value'>{prop.value}</Box>
                        <Box className='item--name'>{prop.profile_prop.name_friendly}</Box>
                    </Box>
                ))}
                <Box className='right-box__item' key=''>
                    <Box className='item--value'>{formatDateTime(singleProfileData.created_at)}</Box>
                    <Box className='item--name'>Added</Box>
                </Box>
            </Box>)
            : <Box className='right-box__item'>-</Box>
    );

    const renderAudiences = () => (
        profileAudiences?.length > 0 ? profileAudiences.map((audience, id) => (
            <Box className='right-box__item' key={id}>{audience.name}</Box>
        ))
            : (
                <>
                    <Box className='right-box__item'>-</Box>
                </>));

    return (
        isLoading ?
            <Box>
                <Loader />
            </Box>
            :
            <Box sx={{width: '75vw'}}>
                <Box display='flex' alignItems='center' pt={2} pl={4}>
                    <Typography variant='h6'>{singleProfileData ? singleProfileData.email : '-'}</Typography>
                </Box>
                <Box display='flex' p={4}>
                    <Box className='left-box' flexGrow={3} mr={2}>
                        <Box className='left-box__top'>
                            <CustomBox style={{flex: 1}} title={'Overall Revenue'}>
                                {renderStat(`$${singleProfileData?.statistics?.revenue_total}`)}
                            </CustomBox>
                            <CustomBox style={{flex: 1}} title={'Open Rate'}>
                                {renderStat(singleProfileData?.statistics?.unique_opens_percentage + '%')}
                            </CustomBox>
                            <CustomBox style={{flex: 1}} title={'Click Rate'}>
                                {renderStat(singleProfileData?.statistics?.unique_clicks_percentage + '%')}
                            </CustomBox>
                            <CustomBox style={{flex: 1}} title={'Messages Sent (Month)'}>
                                {renderStat(singleProfileData?.statistics?.sends_total_last_30_days)}
                            </CustomBox>
                        </Box>
                        <Box className='left-box__activity'>
                            <CustomBox title={'Activity'}>
                                {renderActivities()}
                            </CustomBox>
                        </Box>
                        <Box className='left-box__logs'>
                            <CustomBox title={'Recent Logs'}>
                                {renderLogs()}
                            </CustomBox>
                        </Box>
                    </Box>
                    <Box className='right-box' flexGrow={1}>
                        <Box className='right-box__container'>
                            <CustomBox title='Details'>
                                {renderDetails()}
                            </CustomBox>
                        </Box>
                        <Box className='right-box__container'>
                            <CustomBox title='Audiences'>
                                {renderAudiences()}
                            </CustomBox>
                        </Box>
                    </Box>
                </Box>
            </Box>
    );
};
export default Profile;
