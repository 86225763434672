import React from 'react';
import CampaignSidebar from '../pages/Campaigns/components/CampaignSidebar';
import JourneyStepEditSidebar from '../pages/Journeys/components/Sidebar/JourneyStepEditSidebar';
import JourneyIssuesSidebar from '../pages/Journeys/components/Sidebar/JourneyIssuesSidebar';
import JourneyStepReportSidebar from '../pages/Journeys/components/Sidebar/JourneyStepReportSidebar';
import {DeliveryReportSidebar} from 'pages/admin/traffic-shape/components';
import JourneyDropoutSidebar from '../pages/Journeys/components/Sidebar/JourneyDropoutSidebar';
import JourneyTriggerSidebar from '../pages/Journeys/components/Sidebar/JourneyTriggerSidebar';

import {useParams, withRouter} from 'react-router';
import SendTestSidebar from '../pages/Campaigns/components/SendTestSidebar';
import {LibrarySidebar} from '../pages/Library/components';
import {CreateCampaign} from '../pages/Campaigns/components';
import useLocalStore from '../useLocalStore';
import Profile from '../pages/Audiences/Profile';
import MergeExitSidebar from '../pages/Journeys/components/Sidebar/MergeExitSidebar';


const SidebarRightToLeft = ({ hideSidebar }) => {
    const [getSidebar, resetSidebar] = useLocalStore((state) => [state.getSidebar, state.resetSidebar]);



    const sidebarPayload = getSidebar();
    const { id } = useParams();

    console.log('setSidebarPayload', sidebarPayload);

    // TODO: these should be rendered conditionally - otheriwse its rendering every single one, just not
    //  displaying them
    const sidebarLookupMap = {
        // Journeys
        'JourneyStepEdit':  <JourneyStepEditSidebar />,
        'JourneyStepReport': <JourneyStepReportSidebar />,
        'JourneyTrigger':  <JourneyTriggerSidebar flowId={id} hideSidebar={hideSidebar} />,
        'JourneyDropout': <JourneyDropoutSidebar isOpen={sidebarPayload.open} hideSidebar={hideSidebar} />,
        'JourneyIssues': <JourneyIssuesSidebar issues={sidebarPayload.params ?? false } />,
        'JourneyMergeExit' : <MergeExitSidebar {...sidebarPayload.params}  />,

        // MISC
        'DeliveryReport': <DeliveryReportSidebar />,
        'Library': <LibrarySidebar template={sidebarPayload.params ?? false } />,

        // messages / campaigns
        'Campaigns': <CampaignSidebar type={sidebarPayload.params?.type ?? false} step={sidebarPayload.params ?? false} />,
        'CreateCampaign': <CreateCampaign step={sidebarPayload.params ?? false} />,
        'SendTest': <SendTestSidebar message={sidebarPayload.params ?? false} />,

        // profiles
        'Customers': <Profile profileId={sidebarPayload?.props?.profileId} />

    };


    const getSidebarComponentFromPayload = (name) => {
        try {
            return sidebarLookupMap[name];
        } catch (e) {
            console.log('Could not find sidebar', name );
            resetSidebar();
        }
    };

    return (
        <>
            {getSidebarComponentFromPayload(sidebarPayload.name )}
        </>
    );
};

export default withRouter(SidebarRightToLeft);
