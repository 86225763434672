import React from 'react';
import { getSmoothStepPath } from 'reactflow';
import { useParams } from 'react-router-dom';
import { useJourneyStepStats } from 'api/Journeys/journey_queries';

const MergeEdge = ({ id, label, sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition, style = {}, data, markerEnd, target, sourceHandle }) => {
    const { id: journeyId } = useParams('id');
    const { isLoading, sourceId, isDisabled } = data;

    const { status, data: step, error, isFetching } = useJourneyStepStats(journeyId, sourceId);
    const borderRadius = 20;
    const borderOffset = 20;
    const smoothStepPath = getSmoothStepPath({ sourceX, sourceY, sourcePosition, targetX, targetY, targetPosition, borderRadius, borderOffset });

    return (
        step ?
            <>
                {status === 'loading' ? ( 'Loading...' ) : status === 'error' ? (
                    <span>Error: {error.message}</span>
                ) : (
                    <>{isFetching ? 'Background Updating...' : <path id={id} className={!isDisabled ? 'react-flow__smoothstep-edge' : 'react-flow__smoothstep-edge disabled'} d={smoothStepPath} markerEnd={markerEnd}/> }</>
                )}
            </> : ''
    );
};

export default MergeEdge;
