import React, { Suspense, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Drawer, IconButton, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
    AccountTreeTwoTone,
    CloseTwoTone,
    CollectionsBookmarkTwoTone,
    EmailTwoTone,
    GroupsTwoTone,
    SettingsApplicationsTwoTone,
    MeetingRoomTwoTone
} from '@mui/icons-material';
import clsx from 'clsx';
import { ConfirmProvider } from 'material-ui-confirm';
import useLocalStore from '../../useLocalStore';
import { useAuth } from '../../contexts/AuthContext';
import { Sidebar as ReactProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import leftArrow from 'assets/icons/main/arrow_menu_close.svg';
import rightArrow from 'assets/icons/main/arrow_menu_open.svg';
import SidebarRightToLeft from 'components/SidebarRightToLeft';
import logoShort from 'assets/icons/main/el_logo_icon.svg';
import logoFull from 'assets/icons/main/el_logo_full.svg';

const appMenuDrawerWidth = 200;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex'
    },
    drawer: {
        width: appMenuDrawerWidth,
        minWidth: 50,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        position: 'relative'
    },
    drawerOpen: {
        width: appMenuDrawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: 'hidden',
        width: theme.spacing(8) + 1,
        [theme.breakpoints.up('sm')]: {
            width: 79
        }
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(4),
        maxWidth: '1480px',
        margin: 'auto'
    },
    content_flex: {
        flexGrow: 1,
        maxWidth: '1400px',
        margin: 'auto'
    },
    toggleButton: {
        position: 'absolute',
        right: -10,
        top: 20,
        zIndex: 999999,
        background: 'white',
        borderRadius: '50%',
        width: 20,
        height: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
        '&:hover': {
            background: '#f0f0f0'
        }
    },
    sidebarWrapper: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    reactProSidebar: {
        height: '100%',
        color: '#061942',
        '& .pro-sidebar-inner': {
            background: 'transparent',
            boxShadow: 'none',
            width: '100%',
        },
        '& .pro-menu': {
            padding: '20px 0',
            width: '100%',
        },
        '& .pro-menu-item': {
            width: '100%',
        },
        '&.pro-sidebar': {
            width: '100% !important',
            minWidth: '100% !important',
        },
    },
    logoContainer: {
        padding: theme.spacing(1),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 56,
        width: '100%',
    },
    logo: {
        maxWidth: '75%',
        maxHeight: '75%',
    },
    mainMenu: {
        flexGrow: 1,
        overflowY: 'auto',
    },
    bottomMenu: {
        marginTop: 'auto',
    },
}));

const fullscreen = ['Dashboard', 'CreateJourney', 'EditCampaigns'];
const sidebarsWithCustomWidth = ['SingleAudience', 'CampaignStatistics', 'CreateJourney', 'Dashboard', 'CampaignsAll', 'Audience'];

const Layout = ({ main, pageName }) => {
    const classes = useStyles();
    const history = useHistory();
    const { logoutAction, currentKratosSession, organizations } = useAuth();
    const [getSidebar, resetSidebar] = useLocalStore((state) => [state.getSidebar, state.resetSidebar]);
    const [appMenuCollapsed, setAppMenuCollapsed] = useState(false);
    const sidebar = getSidebar();

    const userEmail = currentKratosSession?.identity?.traits?.email;
    const orgName = organizations[0].name;

    const toggleAppMenuCollapsed = () => setAppMenuCollapsed((state) => !state);
    const hideSidebar = async () => resetSidebar();
    const handleLogout = async () => {
        console.log('logout with auth.logoutAction');
        await logoutAction(history);
    };

    return (
        <ConfirmProvider>
            <div className={classes.root}>
                <Drawer
                    variant='permanent'
                    className={clsx(classes.drawer, {
                        [classes.drawerOpen]: !appMenuCollapsed,
                        [classes.drawerClose]: appMenuCollapsed
                    })}
                    classes={{
                        paper: clsx({
                            [classes.drawerOpen]: !appMenuCollapsed,
                            [classes.drawerClose]: appMenuCollapsed
                        })
                    }}
                >
                    <div className={classes.toggleButton} onClick={toggleAppMenuCollapsed}>
                        <img src={!appMenuCollapsed ? leftArrow : rightArrow} alt='toggle' style={{ width: 10, height: 10, zIndex:999999 }} />
                    </div>
                    <ReactProSidebar
                        collapsed={appMenuCollapsed}
                        className={classes.reactProSidebar}
                        width={appMenuDrawerWidth}
                    >
                        <div className={classes.sidebarWrapper}>
                            <div className={classes.logoContainer}>
                                <img
                                    src={!appMenuCollapsed ? logoFull : logoShort}
                                    alt='Logo'
                                    className={classes.logo}
                                    onClick={toggleAppMenuCollapsed}
                                />
                            </div>
                            <div className={classes.mainMenu}>
                                <Menu>
                                    <MenuItem icon={<EmailTwoTone/>} component={<Link to='/campaigns'/>}>
                                        Campaigns
                                    </MenuItem>
                                    <MenuItem icon={<AccountTreeTwoTone/>} component={<Link to='/journeys/active'/>}>
                                        Journeys
                                    </MenuItem>
                                    <SubMenu label='Profiles' icon={<GroupsTwoTone/>}>
                                        <MenuItem component={<Link to='/audiences'/>}>Audiences</MenuItem>
                                        <MenuItem component={<Link to='/import-sessions'/>}>Import</MenuItem>
                                        <MenuItem component={<Link to='/profile-props'/>}>Properties</MenuItem>
                                    </SubMenu>
                                    <SubMenu label='Library' icon={<CollectionsBookmarkTwoTone/>}>
                                        <MenuItem component={<Link to='/library'/>}>Templates</MenuItem>
                                    </SubMenu>
                                    <SubMenu label='Setup' icon={<SettingsApplicationsTwoTone/>}>
                                        <MenuItem component={<Link to='/settings'/>}>Settings</MenuItem>
                                        <MenuItem component={<Link to='/email-sender-profiles'/>}>Email Sender</MenuItem>
                                        <MenuItem component={<Link to='/email-sending-domains'/>}>Sending Domain</MenuItem>
                                    </SubMenu>
                                    <SubMenu label='Delivery'>
                                        <MenuItem component={<Link to='/vmta-pools'/>}>Send Pools</MenuItem>
                                    </SubMenu>
                                    <SubMenu label='Admin'>
                                        <MenuItem component={<Link to='/admin/suppression-lists/optizmo'/>}>Optizmo</MenuItem>
                                        <MenuItem component={<Link to='/admin/campaigns/current'/>}>
                                            Upcoming/Active Campaigns
                                        </MenuItem>
                                    </SubMenu>
                                </Menu>
                            </div>
                            <div className={classes.bottomMenu}>
                                <Menu>
                                    <MenuItem>{userEmail}</MenuItem>
                                    <MenuItem>{orgName}</MenuItem>
                                    <MenuItem icon={<MeetingRoomTwoTone/>} onClick={handleLogout}>
                                        Logout
                                    </MenuItem>
                                </Menu>
                            </div>
                        </div>
                    </ReactProSidebar>
                </Drawer>
                <main
                    className={`${
                        fullscreen.includes(main.props.subName) ? classes.content_flex : classes.content
                    } ${!appMenuCollapsed && 'drawer_open'}`}
                >
                    <Suspense fallback={null}>{main}</Suspense>
                </main>
                <Drawer
                    anchor='right'
                    open={sidebar?.open ?? false}
                    onClose={hideSidebar}
                    disableEnforceFocus
                    style={{ zIndex: 1350 }}
                >
                    <Box className='context__custom-width context'>
                        <IconButton size='small' variant='contained' onClick={hideSidebar} className='context-close'>
                            <CloseTwoTone />
                        </IconButton>
                        <SidebarRightToLeft page={pageName} hideSidebar={hideSidebar} />
                    </Box>
                </Drawer>
            </div>
        </ConfirmProvider>
    );
};

export default Layout;