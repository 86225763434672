import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

const useLocalStore = create(
    persist(
        (set, get) => ({
            // menuState: false,
            // sidebar: {},
            setSidebar: (payload) => set((state) => ({ sidebar: payload })),
            resetSidebar: () => set((state) => ({ sidebar:  { open: false, name: false, sidebar: false, params: false, props: false } })),
            getSidebar: (id) => get().sidebar
        }),
        {
            name: 'elemental', // name of the item in the storage (must be unique)
            storage: createJSONStorage(() => sessionStorage) // (optional) by default, 'localStorage' is used
        }
    )
);

export default useLocalStore;