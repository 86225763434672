import {useMutation, useQueryClient} from '@tanstack/react-query';
import React, {useState} from 'react';
import {apiV1OrgUri} from 'constants/apiRoutes';
import * as http from 'util/elemental_axios_client';
import {makeStyles} from '@mui/styles';
import QueryBuilder from 'components/QueryBuilder/QueryBuilder';
import {Box} from '@mui/material';
import {Button} from '@mui/material';
import MutationLoadingButton from 'components/MutationLoadingButton';


// TODO: disable the save button based on the query builder validation state
// TODO: use MutationButton
// passing the id's a temp quick hack - need a btter solution
const BranchFilterEdit = ({  editObj, onChangeEditState, journeyId, journeyStepId }) => {

    const queryClient = useQueryClient();

    const [elementalFilterData, setElementalFilterData] = useState(editObj.filter_data);
    const [raqbJsonTree, setRaqbJsonTree] = useState(editObj.filter_tree_json);

    const mutationBaseUrl = `${apiV1OrgUri}/flows/${journeyId}/steps/branch_filter/${journeyStepId}/filter/draft`;

    const putMutation = useMutation(
        async ( data) => {
            return await http.put(`${mutationBaseUrl}`, data );
        }, {
            onSuccess: (data) => {
                queryClient.invalidateQueries(['journey_step', journeyStepId]);
                if (onChangeEditState) {
                    onChangeEditState('success');
                }
            }
        });

    // update the draft with the current state of the filter
    const saveDraft = () => {
        putMutation.mutate({
            filter_data: elementalFilterData,
            raqb_json_tree: raqbJsonTree
        });
    };

    const handleQueryBuilderChange = (elementalFilterData, raqbJsonTree) => {
        setElementalFilterData(elementalFilterData);
        setRaqbJsonTree(raqbJsonTree);
    };

    const handleCancel = () => {
        console.log('EditBranchFilter handleCancel');
        if (onChangeEditState) {
            onChangeEditState('cancel');
        }
    };

    const useStyles = makeStyles({
        content: {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            flexGrow: 1
        }});

    const classes = useStyles();

    const content = ( <div className='branch--filter'>
        <QueryBuilder
            initialElementalFilterData={editObj.filter_data}
            initialRaqbJsonTree={editObj.raqb_json_tree}
            onChange={handleQueryBuilderChange}
        />
    </div> );

    return ( <div className={classes.content}>
            <Box className='flow_query_filter create_audience'>{content}</Box>
            <Box display='flex' justifyContent='space-between' sx={{mx: 1, my: 1}}>
                <Button onClick={handleCancel} className='new_primary_button' style={{marginRight: 'auto'}}>
                    Cancel
                </Button>
                <MutationLoadingButton
                    mutation={null}
                    onClick={saveDraft}
                    className='new_primary_button'
                    type='submit'
                    style={{marginLeft: 'auto'}}
                >
                    Save
                </MutationLoadingButton>
            </Box>
        </div>
    );
};

export default BranchFilterEdit;