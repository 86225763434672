import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import {Box} from '@mui/material';

// TODO: if there is a single filter in a group, don't show the operator
const FilterGroup = ({filter}) => {

    const filters_count = ( filter.filters.count );

    if ( !filter ){
        return <></>;
    }

    return (
        <>
            { filters_count > 1 && <Box>{filter.operator == 'AND' ? 'all' : 'any'} conditions</Box> }
            <Box>
                <List>
                    {filter.filters.map((filter) => {
                        if( filter.filter_type === 'filter'  ) {
                            return <ListItem key={filter.readable}>
                                <ListItemText primary={filter.readable} />
                                   </ListItem>;
                        }
                        else if (filter.filter_type === 'filter_group' ) {
                            return <ListItem key={filter.operator}>
                                <FilterGroup filter={filter}/>
                                   </ListItem>;
                        }
                    })}
                </List>
            </Box>
        </>);
};

const FilterReadable = ({filterData}) => {

    if ( !filterData ){
        return <></>;
    }

    if ( filterData.filter_type === 'filter' ){
        return (
            <Box>{filterData.readable}</Box>
        );
    }
    else if ( filterData.filter_type === 'filter_group' ){
        return (
            <FilterGroup filter={filterData}/>
        );
    }
};


export default FilterReadable;
