import React, { useState, useEffect } from 'react';
import { Box, Snackbar, OutlinedInput, Typography } from '@mui/material';
import MutationButton from '@mui/lab/LoadingButton';
import {apiV1OrgUri} from 'constants/apiRoutes';
import {useMutation} from '@tanstack/react-query';
import * as http from '../../../util/elemental_axios_client';

const LibrarySidebar = ({ template }) => {

    const [ templateName, setTemplateName ] = useState('');
    const [ feedback, setFeedback ] = useState(false);
    const [ feedbackMessage, setFeedbackMessage ] = useState('');

    const onChange = (event) => {
        setTemplateName(event.target.value);
    };

    const mutationUrl = `${apiV1OrgUri}/content/email/templates`;

    const mutationAddTemplate = useMutation(
        {
            mutationFn: async ( payload ) => {
                return await http.post(mutationUrl, payload );
            },
            onSuccess: () => {
                setFeedback(true);
                setFeedbackMessage('Successfully saved to My Templates.');
            },
            onError: (error) => {
                setFeedback(true);
                setFeedbackMessage('Unexpected error occured while saving to My Templates.');
            }
        }
    );

    const saveAsTemplate = async () => {

        const payload = {
            title: templateName,
            template: template.template
        };

        mutationAddTemplate.mutate(payload);

    };

    return (
        <>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={feedback}
                autoHideDuration={6000}
                onClose={() => setFeedback(false)}
                message={feedbackMessage}
            />
            <Box className='flow-sidebar'>
                <Box className='flow-sidebar__header'>
                    <Box>
                        <Typography component='div' variant='subtitle1' className='flow-sidebar_title' style={{border: 0}}>
                            Save to My Templates
                        </Typography>
                    </Box>
                </Box>
                <Box className='flow-sidebar__body' p={0} style={{width: '90%'}}>
                    <Box className='flow-sidebar__body-content' p={2} mt={2}>
                        <Typography variant='subtitl1'>Template Name</Typography>
                        <OutlinedInput id='outlined-basic' type='text' value={templateName} onChange={onChange} fullWidth style={{marginTop: 10}} autoFocus/>
                        <MutationButton mutation={mutationAddTemplate} className='new_primary_button' style={{marginLeft: '81%', marginRight: 0, marginTop: 25}} onClick={saveAsTemplate}>Save</MutationButton>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default LibrarySidebar;
