import React, {useState, useEffect} from 'react';
import {
    FormControl,
    Select,
    MenuItem,
    TextField,
    Tooltip, Button
} from '@mui/material';

import { useImmer } from 'use-immer';

import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

const CartFilter = ( { initialCartFilter , onChangeCartFilter, label = 'filter on cart', active=true} ) => {

    const [ cartFilter, setCartFilter ] = useImmer(initialCartFilter);
    const [ isEditing, setIsEditing ] = useState(false );

    // Fire the onChangeCartFilter handler
    useEffect(() => {
        if ( onChangeCartFilter ) {
            onChangeCartFilter(cartFilter);
        }
    },[cartFilter]);

    const discardEdits = () => {
        setCartFilter( null );
        setIsEditing(false);
    };

    const startEditing = () => {
        if(cartFilter == null) {
            setCartFilter({
                subfilter_type: 'price',
                condition: 'gt',
                value: 0
            });
        }
        setIsEditing(true);
    };

    const showForm = cartFilter !== null || isEditing;

    const subFilterModelNumericFields = [{key: 'price', label: 'Total price'}, {key: 'item_count', label: 'Item count'}];
    const subFilterModelConditions = [{key: 'eq', label: 'equals'}, {key: 'gt', label: 'is more than'}, {key: 'ge', label: 'is at least'}, {key: 'lt', label: 'is less than'}, {key: 'le', label: 'is at most'}];

    const form = (
        <>
            <div className='subfilter_custom--content'>
                <span>where</span>
                <FormControl>
                    <Select
                        MenuProps={{ style: {zIndex: 35001} }}
                        onChange={(e) => setCartFilter( draft => { draft.subfilter_type = e.target.value; })}
                        value={cartFilter?.subfilter_type}
                        name='subFilterType'
                        displayEmpty
                        renderValue={
                            cartFilter?.subfilter_type !== null ? undefined : () => <div>.. order attribute ..</div>
                        }
                    >
                        {subFilterModelNumericFields.map((subFilterField, i) =>
                            <MenuItem key={i} value={subFilterField.key}>{subFilterField.label}</MenuItem>
                        )}
                    </Select>
                </FormControl>
                <FormControl>
                    <Select
                        MenuProps={{style: {zIndex: 35001}}}
                        onChange={(e) => setCartFilter(draft => {
                            draft.condition = e.target.value;
                        })}
                        value={cartFilter?.condition}
                        name='subFilterCondition'
                        displayEmpty
                        renderValue={
                            cartFilter?.condition !== null ? undefined : () => <div>.. comparison ..</div>
                        }
                    >
                        {subFilterModelConditions.map((subFilterCondition, i) =>
                            <MenuItem key={i} value={subFilterCondition.key}>{subFilterCondition.label}</MenuItem>
                        )}
                    </Select>
                </FormControl>
                <TextField
                    type='number'
                    InputProps={{inputProps: {min: 1}}}
                    onChange={(e) => setCartFilter( draft => {draft.value = parseInt(e.target.value);})}
                    value={cartFilter?.value}
                    name='totalPrice' />
                <Tooltip title='Remove Subfilter'><div className='subfilter--close_btn' onClick={discardEdits}> <RemoveCircleOutlineIcon /> </div></Tooltip>

            </div>
        </>
    );


    return (
        <div className={ `${(active) ? 'active' : ''} subfilter`}>
            { showForm ?
                form :
                <Button variant='outlined' className={'disabled subFilter--add_btn'} style={{height: '30px'}} onClick={startEditing}>+ {label}</Button>
            }
        </div>
    );


};


export default CartFilter;