import React, { Suspense } from 'react';
import Grid from '@mui/material/Grid';

const Basic = ({main, id}) => {
    return (
        <>
            <Grid container id={id}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Suspense fallback={null}>{main}</Suspense>
                </Grid>
            </Grid>
        </>
    );
};

export default Basic;
