/* eslint-disable react/display-name */
import React, { memo } from 'react';
import { useParams } from 'react-router-dom';
import { Handle, Position } from 'reactflow';
import EditNodeHeader from './Common/EditNodeHeader';
import { Typography  } from '@mui/material';
import { useJourneyStep } from '../../../../../api/Journeys/journey_queries';
import  useLocalStore  from '../../../../../useLocalStore';

const HANDLE_STYLE = {
    height: 1,
    width: 1,
    position: 'absolute',
    background: 'transparent', //'#E6F5FD',
    boxSizing: 'border-box',
    border: '2px solid transparent',
    bottom: '0px'
};

const BranchFilterEditNode = memo(({ data, isConnectable }) => {
    const { id } = useParams();
    const { isDisabled, stepId } = data;

    const { data: step } = useJourneyStep(id, stepId);

    const [setSidebar] = useLocalStore((state) => [state.setSidebar]);

    const handleSidebar = (event) => {
        const payload = {
            open: true,
            name: 'JourneyStepEdit',
            params: {
                stepId: data.stepId
            }
        };
        setSidebar(payload);
    };

    return (
        step ?
            <div className={!isDisabled ? 'flow-branch-node' : 'flow-branch-node disabled'}>
                <Handle
                    type='target'
                    position={Position.Top}
                    style={{ left: '50%', height: 0, border: 0, background: 'transparent' }}
                    isConnectable={isConnectable}
                />

                <EditNodeHeader step={step} handleSidebar={handleSidebar} label={step?.filter_draft?.label ?? step?.filter?.description ?? step?.description} />

                <Handle
                    type='source'
                    position={Position.Bottom}
                    id='match'
                    style={{...HANDLE_STYLE, left: '38%'}}
                    isConnectable={isConnectable}
                />
                <Handle
                    type='source'
                    position={Position.Bottom}
                    id='no_match'
                    style={{...HANDLE_STYLE, left: '60%'}}
                    isConnectable={isConnectable}
                />
            </div>
            : <></>
    );
});

export default BranchFilterEditNode;
