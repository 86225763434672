import React, {useCallback, useEffect, useState} from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    IconButton,
    InputBase,
    InputLabel,
    MenuItem,
    Select,
    Tooltip,
    Typography
} from '@mui/material';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {useNodesState} from 'reactflow';
import {useParams} from 'react-router-dom';
import {useJourney, useJourneyStep} from '../../../../api/Journeys';
import useLocalStore from '../../../../useLocalStore';

import {
    CallSplitRounded,
    CompareArrowsTwoTone,
    DeleteRounded,
    EmailTwoTone,
    Sms,
    TimelapseTwoTone
} from '@mui/icons-material';
import {useConfirm} from 'material-ui-confirm';
import {enqueueSnackbar} from 'notistack';
import * as http from '../../../../util/elemental_axios_client';
import BranchFilterSidebar from './BranchFilterSidebar';
import WaitSidebar from './WaitSidebar';
import MessageSMSSidebar from './MessageSMSSidebar';
import MessageEmailSidebar from './MessageEmailSidebar';

const DeleteStepDialog = ({ open, onClose, onConfirm }) => {
    const [branchNextEdgeKeyToFollowOnDelete, setBranchNextEdgeKeyToFollowOnDelete] = useState('match');

    const handleBranchNextEdgeKeySelection = (event) => {
        setBranchNextEdgeKeyToFollowOnDelete(event.target.value);
    };

    const handleConfirm = () => {
        onConfirm(branchNextEdgeKeyToFollowOnDelete);
    };

    return (
        <Dialog open={open} onClose={onClose}
                sx={{
                    zIndex: 1450,
                    '& .MuiDialog-paper': {
                        zIndex: (theme) => 1450 + 2,
                    },
                }}
        >
            <DialogTitle>Delete step</DialogTitle>
            <DialogContent>
                <p>Select the branch that you want to keep.</p>
                <FormControl fullWidth size='small' sx={{ mt: 2 }}>
                    <InputLabel id='branch-label'>Branch</InputLabel>
                    <Select
                        labelId='branch-label'
                        value={branchNextEdgeKeyToFollowOnDelete}
                        onChange={handleBranchNextEdgeKeySelection}
                        label='Branch'

                        MenuProps={{
                            style: {zIndex: 35001}
                        }}

                    >
                        <MenuItem value='match'>Match</MenuItem>
                        <MenuItem value='no_match'>No Match</MenuItem>
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleConfirm} color='primary'>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};




const JourneyStepEditSidebar = ({ history }) => {
    const queryClient = useQueryClient();
    const { id: journeyId } = useParams();

    const [getSidebar, resetSidebar] = useLocalStore((state) => [state.getSidebar, state.resetSidebar]);
    const sidebar = getSidebar();

    // left for now since it is used to pass in the prev edge key for branches which powers the deletion branch chooser and is used in the branch component at the moment
    const stepState = sidebar.params;
    const stepId = stepState.stepId;

    const confirm = useConfirm();

    const [ showEditLabel, setShowEditLabel] = useState(false);
    const [ label, setLabel ] = useState(false);

    const { data: journeyStep, isSuccess: isSuccessJourneyStep } = useJourneyStep(journeyId, stepId);
    const { data: journey, isSuccess: isSuccessJourney } = useJourney(journeyId);

    const [showDeleteChooseBranchDialog, setShowDeleteChooseBranchDialog] = useState(false);

    const mutationFlipSplit = useMutation( {
        mutationFn: async ({ id, stepId }) => {
            return await http.put(`/flows/${journey.id}/${journeyStep.id}/swap`);
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['journey', journey.id]);
            queryClient.invalidateQueries(['adjacencyList', journey.id]);
        }
    });

    const mutationUpdateStepLabel = useMutation( {
        mutationFn: ({ payload }) => {
            return http.put(`/flows/${journey.id}/steps/common/${journeyStep.id}/label`, payload);
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['journey_step', journeyStep.id]);
        }
    });

    const mutationDeleteStep = useMutation({
        mutationFn: ( { stepId, payload } ) => {
            return http.remove(`/flows/${journey.id}/steps/${stepId}`, payload);
        },
        onSuccess: () => {
            console.log('invalidate', ['journey', journey.id] );
            // TODO: ideally it actually just sets the new adjacency list from the return to avoid a double fetch
            queryClient.invalidateQueries(['journeys', journey.id]);
            // queryClient.invalidateQueries(['adjacencyList', journey.id]);
            resetSidebar();
            enqueueSnackbar(`${journey.id} ${stepId} Deleted`);
        }
    });

    // When updating the labels, this reference allows updating it in the state
    const [nodes, setNodes, onNodesChange] = useNodesState([]);

    // this should be part of the step type config structure
    const hasCustomWidth = (journey?.status === 'active' && journeyStep?.type === ('email' || 'sms')) || journeyStep?.type === ('email' || 'sms');

    useEffect(() => {
        journeyStep && setLabel(journeyStep.label);
    }, [journeyStep]);

    const onFlip = () => {
        mutationFlipSplit.mutate();
    };

    const onChangeLabel = () => {
        setShowEditLabel((state) => !state);
    };

    const handleStepLabelChange = (e) => {
        setLabel(e.target.value);
    };

    const onStepLabelUpdate = () => {
        if(journeyStep?.label !== label) {
            setNodes(nodes.filter(el => el.data.stepId !== stepId));
            mutationUpdateStepLabel.mutate( {payload: {label}} );
        }
        setShowEditLabel(false);
    };

    const handleDeleteStepButtonClick = () => {
        // Check the condition here
        const shouldShowDialog = journeyStep?.type === 'branch_filter';

        if (shouldShowDialog) {

            setShowDeleteChooseBranchDialog(true);
        } else {
            // Call the other method directly
            confirm( {
                description: `Are you sure you want to delete step ${stepId} ?`,
                dialogProps: {
                    sx: {
                        zIndex: 1450,
                        '& .MuiDialog-paper': {
                            zIndex: (theme) => 1450 + 1,
                        }
                    },
                }
            }).then(
                () => {
                    //
                    mutationDeleteStep.mutate({ stepId, payload: {}});
                }).catch(
                () => {}
            );
        }
    };

    const handleDeleteBranchDialogConfirm = (branchNextEdgeKeyToFollowOnDelete) => {

        setShowDeleteChooseBranchDialog(false);
        confirm({
            title: 'Delete step',
            description: `Are you sure you want to delete this step and keep the "${branchNextEdgeKeyToFollowOnDelete}" branch?`,
            confirmationText: 'Delete',
            cancellationText: 'Cancel',
            dialogProps: {
                sx: {
                    zIndex: 1450,
                    '& .MuiDialog-paper': {
                        zIndex: (theme) => 1450 + 1,
                    }
                },
            }
        }).then(() => {


            // Implement your delete logic here
            mutationDeleteStep.mutate({stepId, payload: { follow_key_from: branchNextEdgeKeyToFollowOnDelete }});
        }).catch(() => {
            // User canceled the action .. nothing to worry about
        });
    };

    if ( isSuccessJourneyStep && isSuccessJourney ) {

        const sidebars = {
            'branch_filter': [<CallSplitRounded color='success' />, <BranchFilterSidebar stepId={journeyStep.id}  hideSidebar={resetSidebar} />],
            'wait': [<TimelapseTwoTone color='warning' />, <WaitSidebar stepId={journeyStep.id}  hideSidebar={resetSidebar} />],
            'email': [<EmailTwoTone color='secondary'/>, <MessageEmailSidebar stepId={journeyStep.id} hideSidebar={resetSidebar} history={history} />],
            'sms':[<Sms color='secondary'/>,<MessageSMSSidebar stepId={journeyStep.id} hideSidebar={resetSidebar} history={history}  />]
        };

        return (
            <Box className='flow-sidebar' display='flex' style={{width: hasCustomWidth ? '22vw' : '15vw'}}>
                <Box className='flow-sidebar__header'>
                    <div onBlur={onStepLabelUpdate}>
                        {showEditLabel ? (
                            <InputBase id='journey-name' value={label} onChange={handleStepLabelChange}
                                       className='flow-sidebar__title' autoFocus/>
                        ) : (
                            <Box onClick={onChangeLabel}>
                                <Tooltip placement='right' title={'Click to change'}>
                                    <Typography component='div' variant='subtitle1' className='flow-sidebar_title'>
                                        {label}
                                    </Typography>
                                </Tooltip>
                            </Box>
                        )}
                    </div>
                    <Box display='inline-flex' alignItems='center'>
                        {journeyStep.type === 'branch_filter' &&
                            <>
                                <Tooltip placement='top' title='Flip Split'>
                                    <IconButton size='small' className='flow-sidebar__actions' onClick={onFlip}>
                                        <CompareArrowsTwoTone fontSize='inherit'/>
                                    </IconButton>
                                </Tooltip>
                                <Divider orientation='vertical' flexItem/>
                            </>
                        }

                        <Tooltip placement='top' title='Delete Step'>
                            <Button
                                variant='contained'
                                startIcon={<DeleteRounded />}
                                size='small'
                                onClick={() => handleDeleteStepButtonClick()}
                            >
                                Delete Step
                            </Button>
                        </Tooltip>
                        <DeleteStepDialog
                            open={showDeleteChooseBranchDialog}
                            onClose={() => setShowDeleteChooseBranchDialog(false)}
                            onConfirm={handleDeleteBranchDialogConfirm}
                        />




                    </Box>
                </Box>
                {/*{(neutralState.error?.errors || neutralState.error) &&*/}
                {/*    <Box mt={4} mr={2} ml={2}>*/}
                {/*        <Alert severity='warning'>Please check your conditions again</Alert>*/}
                {/*    </Box>}*/}
                {sidebars[journeyStep.type][1]}
            </Box>
        );
    }
    else {
        return <Box>Loading ..</Box>;
    }
};

export default JourneyStepEditSidebar;
