import React from 'react';
import { Skeleton, Typography } from '@mui/material';

const EditNodeBody = (props) => {
    const { children, status } =  props;
    const hasChildren = Boolean(children);
    return (
        <>
            {!status &&
                <div className='flow-node__body'>
                    <Typography variant='caption' component='div' style={{fontWeight: 400, marginLeft: 7, fontSize: 14, marginRight: 5}}>
                        <Skeleton animation='wave' width={250} variant='text' />
                        <Skeleton animation='wave' width={250} variant='text' />
                    </Typography>
                </div>
            }
            {hasChildren && <div className='flow-node__body'>{children}</div>}
        </>
    );
};

export default EditNodeBody;
