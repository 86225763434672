import {Accordion, AccordionDetails, AccordionSummary, Typography} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {formatDateTime} from 'util/helper';
import React from 'react';
import EmailContentOverviewSimple from 'pages/Journeys/components/Sidebar/MessageEmail/EmailContentOverviewSimple';

const VariantStrategyOverview = ({variantStrategy}) => {
    // use allocations / allocations_history / allocations_draft based on strategy status

    const allocations = variantStrategy.start ? ( variantStrategy.end ? variantStrategy.allocations_history : variantStrategy.allocations ) : variantStrategy.allocations_draft;

    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                {variantStrategy.type} with {allocations.length} from {formatDateTime(variantStrategy.start)} to {formatDateTime(variantStrategy.end)}<br/>
            </AccordionSummary>
            <AccordionDetails>
                {allocations.map((allocation, i) => (
                    <Accordion key={i}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>{allocation.variant.subject}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <EmailContentOverviewSimple contentContainer={allocation.variant}/>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </AccordionDetails>
        </Accordion>
    );
};

export default VariantStrategyOverview;