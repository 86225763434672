import React from 'react';
import { useParams } from 'react-router-dom';
import { useJourneyStepStats } from '../../../../../api/Journeys/journey_queries';
import { Box, Typography } from '@mui/material';
import { GroupTwoTone, BackspaceTwoTone, CheckCircleTwoTone, ErrorTwoTone } from '@mui/icons-material';
import StatsBranchFilter from '../../Stats/StatsBranchFilter';



const BranchFilterSidebar = ({ stepId }) => {
    const { id } = useParams();
    const { status, data, error, isFetching } = useJourneyStepStats(id, stepId);

    return (
        <Box className='flow-sidebar__body' p={0}>
            <Box className='flow-sidebar__body-content'>
                {status === 'loading' ? (
                    'Loading...'
                ) : status === 'error' ? (
                    <span>Error: {error.message}</span>
                ) : (
                    <>{isFetching ? 'Background Updating...' : <StatsBranchFilter stats={data} />}</>
                )}
            </Box>
        </Box>
    );
};

export default BranchFilterSidebar;
