export const initialState = {
    ready: false,
    fetching: false,
    fetched: false,
    saving: false,
    saved: false,
    error: null,
    data: null
};
export const neutralState = {
    fetching: false,
    fetched: false,
    saving: false,
    saved: false,
    error: null
};