/* eslint-disable react/display-name */
import React, {useEffect} from 'react';
import { useParams } from 'react-router-dom';
import { Handle, Position } from 'reactflow';
import EditNodeHeader from './Common/EditNodeHeader';
import { Typography  } from '@mui/material';
import { useJourneyStep } from '../../../../../api/Journeys/journey_queries';
import useLocalStore from '../../../../../useLocalStore';

const HANDLE_STYLE = {
    height: 13,
    width: 13,
    position: 'absolute',
    background: 'transparent', //'#E6F5FD',
    boxSizing: 'border-box',
    border: '2px solid transparent',
    bottom: '0px'
};

const WaitEditNode = React.memo(({ data, isConnectable }) => {
    const { id } = useParams();
    const { isDisabled, stepId, status } = data;

    // TODO: probably don't need to be loading the stepData via a query .. its actually in data.step
    const { data: step } = useJourneyStep(id, stepId);

    const [setSidebar] = useLocalStore((state) => [state.setSidebar]);

    const handleSidebar = (event) => {
        const payload = {
            open: true,
            name: 'JourneyStepEdit',
            params: {
                stepId: stepId
            }
        };
        setSidebar(payload);
    };

    const onDrop = ( event ) => {
        event.preventDefault();
        console.log('dropped');
    };

    const onDragOver = ( event ) => {
        event.preventDefault();  // needed for drop event to fire
        return false;
    };

    const onDragEnter = ( event ) => {
        console.log('drag enter');
    };

    const onDragLeave = ( event ) => {
        console.log('drag leave');
    };

    const label = step?.label_wait;

    return (
        step ?
            <div className={!isDisabled ? 'flow-wait-node drop-target-available' : 'flow-wait-node disabled'}
                 onDrop={onDrop}
                 onDragOver={onDragOver}
                 onDragEnter={onDragEnter}
                 onDragLeave={onDragLeave}

            >
                <Handle
                    type='target'
                    position={Position.Top}
                    style={{ height: 0, border: 0, background: 'transparent' }}
                    isConnectable={isConnectable}
                />

                <EditNodeHeader step={step} handleSidebar={handleSidebar} label={ label } />

                <Handle
                    type='source'
                    position={Position.Bottom}
                    id='match'
                    style={HANDLE_STYLE}
                    isConnectable={isConnectable}
                />
            </div>
            : <></>
    );
});

export default WaitEditNode;
