import React, {useEffect, useState} from 'react';
import {apiV1OrgUri} from 'constants/apiRoutes';
import {useMutation} from '@tanstack/react-query';
import * as http from '../util/elemental_axios_client';
import CircularProgress from '@mui/material/CircularProgress';
import thumbnailEmpty from 'assets/images/email-content-no-template-placeholder-image.png';
import {EmptyState} from 'components/index';
import { Image } from 'mui-image';

const ContentEmailThumbnailImg = ( {emailContent, ...imageProps} ) => {

    const [ screenshotName, setScreenshotName ] = useState(null);
    const [ hasTemplate, setHasTemplate] = useState( null);

    const describeContent = (emailContent) => {

        console.log('emailContent', emailContent);
        let baseApiScreenshotsUrl = '';
        // based on the existence of the content_source, we know its a variant
        const contentType = emailContent?.content_source ? 'variant' : 'message';
        if ('variant' == contentType ){
            if (emailContent.content_source == 'private') {
                // variant
                baseApiScreenshotsUrl = `${apiV1OrgUri}/messages/${emailContent.message_type}/${emailContent.message_id}/variants/${emailContent.id}/screenshots`;
            }
            else {
                // shared variant - message url built from the variant object
                baseApiScreenshotsUrl = `${apiV1OrgUri}/messages/${emailContent.message.message_type}/${emailContent.message_id}/screenshots`;
            }
        }
        else {
            // message
            baseApiScreenshotsUrl = `${apiV1OrgUri}/messages/${emailContent.message_type}/${emailContent.id}/screenshots`;
        }
        return [ contentType, baseApiScreenshotsUrl ];
    };

    const contentHasTemplate = (emailContent) => {
        return ( emailContent.template != null && Object.entries(emailContent.template).length !== 0 );
    };

    let [ contentType, baseApiScreenshotsUrl ] = describeContent(emailContent);

    useEffect(() => {
        setScreenshotName(emailContent.screenshot_name);
        setHasTemplate(contentHasTemplate(emailContent));
        if ( emailContent.screenshot_name == null && contentHasTemplate(emailContent)) {
            console.log(`${emailContent.id} : regenerate`);
            generateThumbnailMutation.mutate(emailContent);
        }
    }, [emailContent]);

    const generateThumbnailMutation = useMutation({
        mutationFn: (em) => {
            let [ contentType, baseApiScreenshotsUrl ] = describeContent(em);
            // console.log(`regenerate with ${baseApiScreenshotsUrl} for ${emailContent.id} input ${em.id}` );
            return http.put(`${baseApiScreenshotsUrl}`);
        },
        onSuccess: (newEmailContent) => {
            // console.log('regenerate result');
            // console.log(newEmailContent);
            setScreenshotName(newEmailContent.screenshot_name);
        }
    });

    //const info = <Box>Thumbnail:<br/>screenshotName {screenshotName} <br/>emailContent.id: {emailContent.id}<br/>emailContent.screenshot_name: {emailContent.screenshot_name}</Box>;
    const info = <></>;

    if ( screenshotName ){
        return <>{info}<Image src={`${baseApiScreenshotsUrl}/${screenshotName}`} {...imageProps} alt={'Message preview'} /></>;
    }
    else {
        if ( !hasTemplate ){
            return <Image src={thumbnailEmpty} {...imageProps} />;
        }
        else {
            // query is going to generate it
            return <>{info}<CircularProgress/></>;
        }
    }

};

export default ContentEmailThumbnailImg;