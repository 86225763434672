import React, {useEffect, useState} from 'react';
import { Box, Typography } from '@mui/material';
import { EmailTwoTone, CallSplitRounded, TimelapseTwoTone } from '@mui/icons-material';
import {useParams} from 'react-router-dom';
import useLocalStore from '../../../../useLocalStore';
import BranchFilterReport from './BranchFilter/BranchFilterReport';
import MessageEmailReport from './MessageEmail/MessageEmailReport';
import WaitReport from './Wait/WaitReport';
import {useJourney, useJourneyStep} from '../../../../api/Journeys';
import {useQueryClient} from '@tanstack/react-query';
import ExitReport from './Exit/ExitReport';
import EnterReport from './Enter/EnterReport';

const JourneyStepReportSidebar = () => {

    const queryClient = useQueryClient();
    const { id: journeyId } = useParams();

    const [getSidebar, resetSidebar] = useLocalStore((state) => [state.getSidebar, state.resetSidebar]);
    const sidebar = getSidebar();
    const stepId = sidebar.params.stepId;
    
    const [ label, setLabel ] = useState(false);

    const { data: journeyStep, isSuccess: isSuccessJourneyStep } = useJourneyStep(journeyId, stepId);
    const { data: journey, isSuccess: isSuccessJourney } = useJourney(journeyId);

    if (!isSuccessJourneyStep){
        return <div>Loading</div>;
    }

    const sidebarsByStepType = {
        'branch_filter': <BranchFilterReport stepId={journeyStep.id} />,
        'wait': <WaitReport stepId={journeyStep.id}  />,
        'email': <MessageEmailReport stepId={journeyStep.id} />,
        'exit': <ExitReport stepId={journeyStep.id} />,
        'enter': <EnterReport stepId={journeyStep.id} />
    };

    return (
        <Box className='flow-sidebar'>
            <Box className='flow-sidebar__header'>
                <Typography component='div' variant='subtitle1' className='flow-sidebar_title' style={{border: 0, cursor: 'regular'}}>
                    {label} Stats
                </Typography>
            </Box>
            {journeyStep && sidebarsByStepType[journeyStep.type]}
        </Box>
    );
};

export default JourneyStepReportSidebar;
