import {Box} from '@mui/material';
import {Typography} from '@mui/material';
import {formatDateTime} from 'util/helper';
import FilterReadable from 'components/FilterReadable';
import React from 'react';

const BranchFilterHistory = ({ historyObj }) => {
    return <div className='branch--filter' >
        <Box display='flex' flexDirection='column' pt={3} px={2}>
            <Typography variant='subtitle1' style={{marginTop: 5, display: 'inline-flex', alignItems: 'center'}}>
                <Typography variant='body2' style={{marginLeft: 10}}>
                    {formatDateTime(historyObj.start)} -> {formatDateTime(historyObj.end)}
                </Typography>
            </Typography>
            <Box><FilterReadable filterData={historyObj.filter_data}/></Box>
        </Box>
    </div>;
};

export default BranchFilterHistory;