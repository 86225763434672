import { initialState, neutralState } from 'redux/helpers/defaultStateHelpers';
import {
    INTEGRATE_SHOPIFY_START, INTEGRATE_SHOPIFY_SUCCESS, INTEGRATE_SHOPIFY_ERROR,
    DISABLE_SHOPIFY_START, DISABLE_SHOPIFY_SUCCESS, DISABLE_SHOPIFY_ERROR
} from './shopify_types';

const fetchShopifyReducer = (state = { ...initialState }, action) => {
    switch (action.type) {
    case INTEGRATE_SHOPIFY_START:
        return { ...state, fetching: true };
    case INTEGRATE_SHOPIFY_SUCCESS:
        return { ...state, ...neutralState, ready: true, fetched: true, data: action.data || null };
    case INTEGRATE_SHOPIFY_ERROR:
        return { ...state, ...neutralState, error: action.error };

    case DISABLE_SHOPIFY_START:
        return { ...state, fetching: true };
    case DISABLE_SHOPIFY_SUCCESS:
        return { ...state, ...neutralState, ready: true, fetched: true, campaign: action.data || null };
    case DISABLE_SHOPIFY_ERROR:
        return { ...state, ...neutralState, error: action.error };
    default:
        return state;
    }
};

export default fetchShopifyReducer;
