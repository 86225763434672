import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Card, CardHeader, CardContent, CardActions, Button, FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material';

const FlowActionTriggerProfileEventForm = ({
    onSubmit,
    flowId,
    triggerType,
    flowTravelActionType,
    journey,
    profileEventTypes,
    noEvents = <></>,
    showButtonLabel = 'Add Event Trigger'
}) => {
    const [showForm, setShowForm] = useState(false);
    const { control, handleSubmit, formState: { errors }, reset } = useForm();

    const allTriggers = [...journey.flow_action_triggers_draft, ...journey.flow_action_triggers];
    const usedProfileEventTypes = allTriggers.map(trigger =>
        trigger.event_query?.profile_event_type
    ).filter(Boolean);
    const availableProfileEventTypes = profileEventTypes.filter(
        type => !usedProfileEventTypes.includes(type.value)
    );

    if (availableProfileEventTypes.length === 0) {
        return noEvents;
    }

    const submitForm = (data) => {
        const payload = {
            [triggerType]: { type: 'profile_event', ...data },
            trigger_type: triggerType,
            flow_travel_action_type: flowTravelActionType
        };
        onSubmit(payload);
        setShowForm(false);
        reset();
    };

    const handleCancel = () => {
        setShowForm(false);
        reset();
    };

    if (!showForm) {
        return (
            <Button variant='contained' color='primary' onClick={() => setShowForm(true)}>
                {showButtonLabel}
            </Button>
        );
    }

    return (
        <Card sx={{ maxWidth: '100%', margin: 'auto' }}>
            <CardContent>
                <form onSubmit={handleSubmit(submitForm)}>
                    <Controller
                        name='profile_event_type'
                        control={control}
                        rules={{ required: 'A profile event type is required' }}
                        render={({ field }) => (
                            <FormControl fullWidth error={!!errors.profile_event_type} sx={{ mb: 2 }}>
                                <InputLabel id='event-query-label'>Profile Event</InputLabel>
                                <Select
                                    MenuProps={{style: {zIndex: 35001}}}
                                    labelId='event-query-label'
                                    label='Profile Event'
                                    {...field}
                                >
                                    {availableProfileEventTypes.map((type) => (
                                        <MenuItem key={type.value} value={type.value}>
                                            {type.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {errors.profile_event_type && (
                                    <FormHelperText>{errors.profile_event_type.message}</FormHelperText>
                                )}
                            </FormControl>
                        )}
                    />
                    <CardActions sx={{ justifyContent: 'flex-end', padding: 0 }}>
                        <Button onClick={handleCancel} variant='outlined' color='secondary'>
                            Cancel
                        </Button>
                        <Button type='submit' variant='contained' color='primary'>
                            Create Trigger
                        </Button>
                    </CardActions>
                </form>
            </CardContent>
        </Card>
    );
};

export default FlowActionTriggerProfileEventForm;