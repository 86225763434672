import React from 'react';

const CustomBox = (props) => {
    const {title, children, style} = props;

    return (
        <div className='custom_box' style={style}>
            <div className='custom_box__title'>{title}</div>
            {children}
        </div>
    );
};
export default CustomBox;