import React, {useState, useEffect, useContext} from 'react';
import { Box, Typography } from '@mui/material';

const CampaignSidebar = ({ type, step }) => {
    const [ errors, setErrors ] = useState(false);
    const Component = step.data;

    useEffect(() => {

        if(step.res && step.res.errors) {
            const err = [];
            Object.entries(step.res.errors).map(error =>
                Object.entries(error).map(([key, issue]) => {
                    console.log(error);
                    return err.push([key, issue]);
                }));
            setErrors(err);
        }
    }, []);

    return (
        <Box className='flow-sidebar'>
            <Box className='flow-sidebar__header'>
                <Typography component='div' variant='subtitle1' className='flow-sidebar_title'>
                    {step.header}
                </Typography>
            </Box>
            <Box className='flow-sidebar__body' style={{minWidth: 'max-content'}}>
                <Component />
            </Box>
        </Box>
    );
};

export default CampaignSidebar;
