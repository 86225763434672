import { useQuery } from '@tanstack/react-query';
import * as http from 'util/elemental_axios_client';
import { apiV1OrgUri } from 'constants/apiRoutes';

export const useDeliveryReports = () => {
    const action = '/settings/forms?type=pmta_query';

    return useQuery(['deliveryReports'], async () => {
        try {
            const response = await http.get(action);
            if (!response) {
                throw new Error('Network response was not ok');
            }
            return response;
        } catch(e) {
            console.log(e);
        }
    });
};
