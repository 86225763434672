import { DateTime } from 'luxon';
import { dateFormat, dateTimeFormat } from './constants';

export const formatDateTime = (date, format = dateTimeFormat) => {
    if (typeof date !== 'string' || date === '') return date;
    return DateTime.fromISO(date).toFormat(format);
};

export const formatDate = (date, format = dateFormat) => {
    if (typeof date !== 'string' || date === '') return date;
    return DateTime.fromISO(date).toFormat(format);
};

export const getOrganizationFromLocalStorage = () => {
    const organizationJsonStr = localStorage.getItem('organization');
    console.log('organizationJsonStr', organizationJsonStr);
    const organization = JSON.parse(organizationJsonStr);
    const org = organization ? organization : {};
    return org;
};

export const redirectLoggedInCurrentUser = () => {
    const params = new URLSearchParams(window.location.search);
    const nextParams = params.get('next');
    // const history = useHistory();

    if (params.has('next')) {
        window.location.replace(nextParams);
    } else {
        window.location.replace('/campaigns/draft');
    }
};

export const loadAppcues = (currentUser) => {
    const load = require('load-script');
    window.AppcuesSettings = {
        enableURLDetection: true
    };
    load('https://fast.appcues.com/115830.js', (err, script) => {
        if (err) {
            console.log(err);
        } else {
            window.Appcues.identify(currentUser.identity.id, { // Unique identifier for current user
                email: currentUser.identity.traits.email
            });
        }
    });
};

export const saveUrlParams = (search) => {
    const params = new URLSearchParams(search);
    const nextParams = params.get('next');
    const urlParam = nextParams?.split('shop_url=')[1];
    if (params.has('next')) localStorage.setItem('next', nextParams);
    if (urlParam) localStorage.setItem('shop_url', urlParam);
};

export const stringToReadable = (string, separator) => {
    return string.replaceAll(separator, ' ');
};

export const logger = (log) => {
    const isDevEnv = process.env.NODE_ENV === 'development';
    if(isDevEnv) {
        console.log(log);
    }
};

export const getNewest = (list, dateAttribute) => {
    if (!Array.isArray(list) || list.length === 0) {
        return null;
    }

    return list.reduce((newest, current) => {
        return new Date(current[dateAttribute]) > new Date(newest[dateAttribute]) ? current : newest;
    });
};



