import React from 'react';
import { Typography, Skeleton, Tooltip, ClickAwayListener, InputBase } from '@mui/material';
import { SmsTwoTone, EmailTwoTone, CallSplitRounded, TimelapseTwoTone } from '@mui/icons-material';
import { flowStateChip, flowSendStates } from '../../../../JourneyConstants';
import { useNodesState } from 'reactflow';
import {useParams} from 'react-router-dom';
import useLocalStore from '../../../../../../useLocalStore';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { setStepLabel } from '../../../../../../api/Journeys';

const EditNodeHeader = ({ step, handleSidebar = false, label = null }) => {

    const stepLabel = label ?? step?.label;

    // TODO: this should be passed in .. there shouldn't be type dependent checks here UNLESS ALL the type dependent checks are in here
    //  as an example, WaitEditNode has WaitDraftNode and WaitActiveNode components defined .. where each one is just setting a label thats passed
    //  as a childe to step header. But icon is not passed.
    const icon = (type) => {
        switch(type){
        case 'email':
            return <EmailTwoTone fontSize='small' />;
        case 'sms':
            return <SmsTwoTone fontSize='small' />;
        case 'wait':
            return <TimelapseTwoTone fontSize='small' />;
        case 'branch_filter':
            return <CallSplitRounded fontSize='small' />;
        default:
            return '';
        }
    };

    const statusBadge = flowStateChip(step.status, step.has_changes, 'small');

    const canSend = step.has_changes && flowSendStates[step.can_activate];

    return (
        <>
            {step ?
                <div className='flow-node__header'
                     style={{ cursor: 'pointer' }}
                     onClick={(e) => handleSidebar && handleSidebar(e)}
                     style={{display: 'flex', alignItems: 'center'}}
                >
                    <Typography className='node-icon' style={{width: '100% !important'}}>
                        {icon(step.type)}
                    </Typography>
                    <Typography variant='caption' component='div' style={{fontWeight: 400, marginLeft: 7, fontSize: 12, marginRight: 5}}>
                        {stepLabel}
                    </Typography>
                    <Typography variant='caption' component='div' style={{display: 'inline-flex', alignItems: 'center', fontWeight: 500, marginLeft: 'auto', fontSize: 14, marginRight: 0, position: 'absolute', right: 0, background: 'white', paddingLeft: 5, paddingRight: 5}}>
                        {statusBadge}
                        {step.has_changes && canSend}
                    </Typography>
                </div>

                :
                <div className='flow-node__header'>
                    <Typography className='node-icon' style={{width: '100% !important'}}>
                        <Skeleton variant='circular' animation='wave' width={15} height={15} />
                    </Typography>
                    <Typography variant='caption' component='div' style={{fontWeight: 400, marginLeft: 7, fontSize: 14, marginRight: 5}}>
                        <Skeleton animation='wave' width={150} variant='text' />
                    </Typography>
                    <Typography variant='caption' component='div' style={{fontWeight: 500, marginLeft: 'auto', fontSize: 14, marginRight: 0}}>
                        <Skeleton animation='wave' width={50} variant='text' />
                    </Typography>
                </div>
        }
        </>
    );
};

export default EditNodeHeader;
