import { createFromCall } from 'util/Type';

const PREFIX = 'Shopify/';

export const CALLS = {
    INTEGRATE_SHOPIFY: `${PREFIX}INTEGRATE_SHOPIFY_START`,
    DISABLE_SHOPIFY: `${PREFIX}DISABLE_SHOPIFY_START`
};

export const INTEGRATE_SHOPIFY_START = CALLS.INTEGRATE_SHOPIFY;
export const INTEGRATE_SHOPIFY_SUCCESS = createFromCall(CALLS.INTEGRATE_SHOPIFY, 'SUCCESS');
export const INTEGRATE_SHOPIFY_ERROR = createFromCall(CALLS.INTEGRATE_SHOPIFY, 'ERROR');

export const DISABLE_SHOPIFY_START = CALLS.DISABLE_SHOPIFY;
export const DISABLE_SHOPIFY_SUCCESS = createFromCall(CALLS.DISABLE_SHOPIFY, 'SUCCESS');
export const DISABLE_SHOPIFY_ERROR = createFromCall(CALLS.DISABLE_SHOPIFY, 'ERROR');
