import React, { useState } from 'react';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { withRouter } from 'react-router';
import { List, ListSubheader, ListItemButton, ListItemText, Box, IconButton, Tooltip, TextField, Typography, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useDeliveryReports } from '../../../../api/Dashboard/dashboard_queries';
import { setDeliveryReport } from '../../../../api/Dashboard/dashboard_mutations';
import { orderBy } from 'lodash';
import { LibraryAddOutlined, LibraryBooksOutlined } from '@mui/icons-material';
import useLocalStore from '../../../../useLocalStore';

const DeliveryReportSidebar = ({ history }) => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();

    const { payload, tab } = useSelector((state) => state.Context.data.element);

    const [setSidebar, resetSidebar] = useLocalStore(
        (state) => [state.setSidebar, state.resetSidebar]
    );

    const [ reportName, setReportName ] = useState('');
    const [ type, setType ] = useState('pmta_query');
    const [ activeTab, setActiveTab ] = useState(tab);

    const handleReportName = (event) => {
        setReportName(event.target.value);
    };

    const { data, isLoading } = useDeliveryReports();

    const { mutate, isLoading: isCreateLoading } = useMutation(setDeliveryReport, {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['deliveryReports']);
        }
    });

    const onCreate = async () => {
        const data = {
            name: reportName,
            type: type,
            form_data: payload
        };

        if(reportName) {
            mutate(data);
            resetSidebar();
        }
    };

    const handleActiveTab = (newValue) => {
        setActiveTab(newValue);
    };

    const handleReportFilters = (payload) => {
        const urlEncoded = new URLSearchParams(payload).toString();
        history.push(`?${urlEncoded}`);
    };

    const reports = data &&
        <List
            sx={{
                width: '100%',
                bgcolor: 'background.paper',
                position: 'relative',
                overflow: 'auto',
                maxHeight: 450,
                '& ul': { padding: 0 }
            }}
            subheader={<ListSubheader style={{paddingLeft: 20, marginTop: 15}}>My Reports</ListSubheader>}
        >
            {orderBy(data, 'id', 'desc').map(report => {
                return (
                    <ListItemButton key={report.id} style={{paddingLeft: 45}} onClick={() => handleReportFilters(report.form_data)}>
                        <ListItemText primary={report.name} />
                    </ListItemButton>
                );
            })}
        </List>;

    const title = activeTab === 0 ? 'All Reports' : 'New Report';

    const headerAction =
        activeTab === 0 ?
            <Tooltip placement='top' title='Create Report'>
                <IconButton className='flow-sidebar__actions' onClick={() => handleActiveTab(1)}>
                    <LibraryAddOutlined fontSize='inherit' />
                </IconButton>
            </Tooltip>
            :
            <Tooltip placement='top' title='View Reports'>
                <IconButton className='flow-sidebar__actions' onClick={() => handleActiveTab(0)}>
                    <LibraryBooksOutlined fontSize='inherit' />
                </IconButton>
            </Tooltip>;

    return (
        <Box className='flow-sidebar' style={{width: 450}}>
            <Box className='flow-sidebar__header'>
                <Typography component='div' variant='subtitle1' className='flow-sidebar_title' style={{borderBottom: 'none'}}>
                    {title}
                </Typography>
                <Box display='inline-flex' alignItems='center'>
                    {headerAction}
                </Box>
            </Box>
            <Box className='flow-sidebar__body' style={{width: '100%', minWidth: 'max-content'}}>
                {activeTab === 0 &&
                    <Box display='flex' flexDirection='column'>
                        {reports}
                    </Box>
                }
                {activeTab === 1 &&
                    <Box className='flow-sidebar__body-content'>
                        <Box display='flex' flexDirection='column'>
                            Report Name
                            <TextField size='small' margin='dense' placeholder='New Report' onChange={handleReportName} value={reportName} variant='outlined' style={{marginTop: 10, background: '#FFF'}}/>

                            <Button size='small' onClick={onCreate} className='new_primary_button' style={{margin: 0, marginTop: 10, marginLeft: 'auto', width: 'max-content'}} autoFocus>
                                Create
                            </Button>
                        </Box>
                    </Box>
                }
            </Box>
        </Box>
    );
};

export default withRouter(DeliveryReportSidebar);
