import {Box, Typography} from '@mui/material';
import {BackspaceTwoTone, GroupTwoTone} from '@mui/icons-material';
import React from 'react';

const StatsWait = ({ stats }) => {

    return (
        <Box className='flow-sidebar__body-hihlighter' display='flex' style={{padding: '5px 0px'}}>
            <Box sx={{width: '100%'}} display='flex' flexDirection='column'>
                <Box display='flex' flexGrow={1} alignItems='center' mb={2}>
                    <GroupTwoTone style={{marginRight: 10}}/>
                    <Typography component='div' variant='subtitle1'>Total Travellers: <span style={{fontWeight: 400}}>{stats.journeyed}</span></Typography>
                </Box>
                <Box display='flex' flexGrow={1} alignItems='center' mb={2}>
                    <GroupTwoTone style={{marginRight: 10}}/>
                    <Typography component='div' variant='subtitle1'>Waiting: <span style={{fontWeight: 400}}>{stats.waiting}</span></Typography>
                </Box>

                <Box display='flex' flexGrow={1} alignItems='center' mb={2}>
                    <BackspaceTwoTone style={{marginRight: 10}}/>
                    <Typography component='div' variant='subtitle1'>Exited Because of Journey Filter: <span style={{fontWeight: 400}}>{stats.global_dropout_filter}</span></Typography>
                </Box>
                <Box display='flex' flexGrow={1} alignItems='center' mb={2}>
                    <BackspaceTwoTone style={{marginRight: 10}}/>
                    <Typography component='div' variant='subtitle1'>Exited Because of Step Filter: <span style={{fontWeight: 400}}>{stats.bypass_filter}</span></Typography>
                </Box>


                {/*<Box display='flex' flexGrow={1} alignItems='center' mb={2}>*/}
                {/*    <LocalAtmTwoTone style={{marginRight: 10}}/>*/}
                {/*    <Typography component='div' variant='subtitle1'>Converted while Waiting: {totalConversions}</Typography>*/}
                {/*</Box>*/}
            </Box>
        </Box>
    );
};

export default StatsWait;