import {getOrganizationFromLocalStorage} from 'util/helper';
const orgId = getOrganizationFromLocalStorage()?.id;

export const kratosPublicUrl = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_KRATOS_PATH}`;
export const apiUrl = `${process.env.REACT_APP_API_URL}`;

export const apiV1OrgUri = `${process.env.REACT_APP_API_URL}/api/v1/${orgId}`;

//Shopify
export const API_SHOPIFY_INTEGRATE_ROUTE = `${apiV1OrgUri}/shopify/integrate`;
export const API_SHOPIFY_DISABLE_ROUTE = `${apiV1OrgUri}/shopify/disable`;
export const API_SHOPIFY_STATUS = `${apiV1OrgUri}/shopify/status`;
export const API_SHOPIFY_TIERS = `${apiV1OrgUri}/shopify/tiers`;
export const API_SHOPIFY_START_BILLING = `${apiV1OrgUri}/shopify/billing/start`;
export const API_SHOPIFY_SUB_STATUS = `${apiV1OrgUri}/shopify/subscription/status`;
export const API_SHOPIFY_SUB_CANCEL = `${apiV1OrgUri}/shopify/billing/cancel`;


//Batches
export const API_BATCHES_ROUTE = `${apiV1OrgUri}/batches/messages`;
